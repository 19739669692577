<template>
  <div>
    <wrapper-filters
        title="Динамика активаций"
        filtersUrl="/statistic/dynamicPhoneActivation/getList"
        filtersUrlMethod="get"
        :setEnabledFilters="{
          dateWithOutTime: {
            enable: true,
            column: 1,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
                {
                  id: 4,
                  title: 'За 1 неделю'
                },
                {
                  id: 6,
                  title: 'За 2 недели'
                },
                {
                  id: 7,
                  title: 'За 3 недели'
                },
                {
                  id: 5,
                  title: 'За месяц'
                },
            ],
            column: 1,
          },
          countries: {
            enable: true,
            noGroupSelect: true,
            closeOnSelect: true,
            allowEmpty: false,
            getDataLink: '/countries/getList',
            placeholder: 'Страны',
            viewLabel: 'titleWithId',
            selectedKey: 'countryId',
            column: 2,
          },
          services: {
            enable: true,
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Сервисы',
            viewLabel: 'titleWithId',
            column: 2
          },
          users: {
            enable: true,
            multiple: true,
            getDataLink: '/users/getUsers',
            placeholder: 'Клиенты',
            specifyKeyForLabel: 'name',
            viewLabel: 'titleWithId',
            column: 3
          },
          providers: {
            enable: true,
            multiple: true,
            getDataLink: '/providers/getList',
            placeholder: 'Провайдеры',
            viewLabel: 'titleWithId',
            column: 3
          },
        }"
        @set-filtered-list="setFilteredList"
    >
    </wrapper-filters>
    <div class="wrapperCanvas">
      <PieChartGenerator
          v-if="loaded"
          :chart-data="chartData"
          :title="title"
      ></PieChartGenerator>
    </div>

  </div>
</template>

<script>
import WrapperFilters from "../../../components/wrapperFilters"
import PieChartGenerator from "../../../components/pieChart";
import GraphDynamic from "../../../components/mixins/GraphDynamicMixin";

export default {
  name: "dynamicPhoneActivationStatistic",
  mixins: [
    GraphDynamic,
  ],
  data() {
    return {
      type: 'pie',
      title: ''
    }
  },
  components: {
    WrapperFilters,
    PieChartGenerator
  },
}
</script>

<style scoped>
.wrapperCanvas {
  position: relative;
  margin: auto;
  height: 30vh;
  width: 45vw;
}
</style>
