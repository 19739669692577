<template>
  <div>
    <v-notification></v-notification>
    <Modal
        v-show="showModalAdd"
        :show="showModalAdd"
        :scrollable="true"
        header-id="modalHeader"
        body-id="modalBody"
        @close="closeModal">
      >
      <template #header>
        Изменить параметры <p>{{ addProviderName }}</p>
        <vue-spinner class="absolute" color="#CE0000" v-if="showLoader"></vue-spinner>
      </template>
      <template #body>
        <div class='params-list'>
          <div class='list-item' v-for='(item, index) in providerParams' :key='index'>
            <p>{{ item['value']['title'] }}</p>
            <div class='title-key'>
              <input autocomplete="off" class="input-params" v-model="item['value']['value']" type="text" placeholder="Введите Значение">
            </div>
          </div>
        </div>
        <button class="col-12 btn-t btn--borderRed flex-center" @click="editParamsWithLoader">Сохранить</button>
      </template>
    </Modal>
    <base-table
        :columns="this.columns"
        :wrapperFiltersData="this.wrapperFiltersData"
        :changeItemWithLoaderMethod="changeItemWithLoaderMethod"
        :changeItemWithLoaderUrl="changeItemWithLoaderUrl"
        :perPage="1000"
        :render-list-to-show="this.listToShow"
        ref="baseTable"
    >
      <template v-slot:base-table-row="props">
        <div class="admin-table-element" style="font-size: 13px">
          <template v-if="props.column.field === 'ratio'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {ratio: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'timeout'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {timeout: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'ban_time'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {ban_time: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'is_active'">
            <button v-if="props.formattedRow[props.column.field]" class="btn btn-success w-100" @click="props.changeItemWithLoader(props.row['id'], {is_active: false})">ON</button>
            <button v-else class="btn btn-danger w-100" @click="props.changeItemWithLoader(props.row['id'], {is_active: true})">OFF</button>
          </template>
          <template v-else-if="props.column.field === 'has_api'">
            <span v-if="props.formattedRow[props.column.field]" class="text-success"><b>YES</b></span>
            <span v-else class="text-danger"><b>NO</b></span>
          </template>
          <template v-else-if="props.column.field === 'replenishment_commission'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {replenishment_commission: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'params'">
            <div class="flex-column">
              <div>
                <table>
                  <template v-for="(value) in props.formattedRow[props.column.field]">
                    <tr>
                      <td class="first-cell">{{ value['title'] }}</td>
                      <td style="padding-left: 5px">{{ value['value'] }}</td>
                    </tr>
                  </template>
                </table>
              </div>
              <div>
                <button class="btn-params" @click="toggleShowModalEditParams(props.formattedRow)">Изменить</button>
              </div>
            </div>
          </template>
          <template v-else-if="props.column.field === 'currency'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {currency: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from "../../../components/baseTable";
import Modal from '../../../components/modal';
import BaseAdminMixin from "../../../admin/components/mixins/BaseAdminMixin";

export default {
  name: "providers",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    BaseTable,
    Modal
  },
  data: function () {
    return {
      wrapperFiltersData: {
        title: "Провайдеры",
        filtersUrl: "/providers/getList",
        startWithRequest: true,
      },
      columns: [
        {
          label: 'ID⠀⠀',
          field: 'id',
          sortFn: 'sortNumber',
          width: '5%',
        },
        {
          label: 'Название',
          field: 'title',
          width: '7%',
        },
        {
          label: 'Приоритет',
          field: 'ratio',
          sortFn: 'sortNumber',
          width: '7%',
        },
        {
          label: 'Активность⠀⠀',
          field: 'is_active',
          width: '7%',
        },
        {
          label: 'Имеет интеграцию⠀⠀',
          field: 'has_api',
          width: '5%',
        },
        {
          label: 'Таймаут (сек)  ',
          field: 'timeout',
          width: '8%',
        },
        {
          label: 'Время бана (сек)  ',
          field: 'ban_time',
          width: '8%',
        },
        {
          label: 'Дата создания⠀⠀',
          field: 'created_at',
          width: '10%',
        },
        {
          label: 'Комиссия при пополнении  ',
          field: 'replenishment_commission',
          width: '8%',
        },
        {
          label: 'Валюта поставщика  ',
          field: 'currency',
          width: '10%',
        },
        {
          label: 'Курс  ',
          field: 'rate',
          width: '7%',
        },
        {
          label: 'Параметры',
          field: 'params',
          width: '15%',
        },
      ],
      changeItemWithLoaderMethod: 'patch',
      changeItemWithLoaderUrl: '/providers/',
      showModalAdd: false,
      addModalItemParam: {
        id: null,
      },
      addProviderName: null,
      providerParams: [],
      messages: [],
    };
  },

  methods: {
    listToShow(list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          title: elem['title'],
          ratio: elem['ratio'],
          is_active: elem['is_active'],
          timeout: elem['timeout'],
          ban_time: elem['ban_time'],
          created_at: elem['created_at'],
          has_api: elem['has_api'],
          replenishment_commission: elem['replenishment_commission'],
          currency: elem['rate'] ? elem['rate']['currency'] : '',
          rate: elem['rate'] ? elem['rate']['rate'] : '',
          params: elem['params'],
        });
      }
      return data;
    },
    async editParamsWithLoader() {
      let params = Object.assign({}, this.addModalItemParam);
      for (let item of this.providerParams) {
        if (item['value']['value']) {
          params[`${item['key']}`] = item['value']['value'];
        }
      }
      await this.doWithStopRequest(() => this.doWithLoader(async () => {
        let response = await this.sendRequest(
            '/providers/editParams',
            'post',
            params
        );
        if (response) {
          this.addModalItemParam = {};
          await this.alertMessage(
              'Item has been changed',
              'info'
          );
          this.$refs.baseTable.refreshListData();
          return this.closeModal();
        }
      }), 'changeItem')
    },
    toggleShowModalEditParams(data) {
      this.providerParams = [];
      this.addModalItemParam.id = data['id'];
      this.addProviderName = data['title'];
      for (let key in data['params']) {
        this.providerParams.push({
          key: key,
          value: data['params'][key]
        });
      }
      return this.showModalAdd = true;
    },
    closeModal() {
      this.addModalItemParam = {};
      return this.showModalAdd = false;
    },
  },
}
</script>

<style lang="scss" scoped>
#modalHeader {
  position: relative;
}

.absolute {
  position: absolute;
  top: 75px;
  left: 46%;
}

.input-params {
  border: none;
  width: 100%;
  padding: 2px 8px;
}

.params-list {
  position: relative;
  margin: 2rem auto;
  width: 90%;
  max-width: 30rem;
  text-align: center;
}

.list-item {
  position: relative;
  background: #c6c2c2;
  padding: 1rem;
  border-radius: 1rem;
  text-align: left;
  will-change: max-height;

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  & + .list-item {
    margin-top: 1rem;
  }

  > .title-key {
    margin-top: 5px;
  }

  > .text {
    width: calc(100% - 2rem);
  }
}

.btn-params {
  border: 1px solid #CE0000;
  color: #ffffff;
  background: #CE0000;
  padding: 0 5px;
  border-radius: 10px;

  &:hover {
    background: #4d1d1d;
  }
}

.first-cell {
  padding-right: 5px !important;
  border-right: 1px solid #000000FF;
  white-space:nowrap;
}
</style>
