var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('wrapper-filters',{attrs:{"title":"Динамика выручки","filtersUrl":"/statistic/dynamic/getList","filtersUrlMethod":"get","setEnabledFilters":{
        date: {
          enable: true,
          column: 1,
        },
        dateSelector: {
          enable: true,
          placeholder: 'Период',
          noGroupSelect: true,
          closeOnSelect: true,
          selectedKey: 'dateSelector',
          data: [
              {
                id: 4,
                title: 'За 1 неделю'
              },
              {
                id: 6,
                title: 'За 2 недели'
              },
              {
                id: 7,
                title: 'За 3 недели'
              },
              {
                id: 5,
                title: 'За месяц'
              },
          ],
          column: 1,
        },
        stepOsX: {
          enable: true,
          placeholder: 'Отрезок шага',
          noGroupSelect: true,
          closeOnSelect: true,
          selectedKey: 'stepX',
          data: [
              {
                id: 1,
                title: 'По часам'
              },
              {
                id: 3,
                title: 'По дням недели'
              },
              {
                id: 5,
                title: 'По дням месяца'
              },
              {
                id: 6,
                title: 'По месяцам'
              },
              {
                id: 7,
                title: 'Месячная выручка (без группировки)'
              }
          ],
          defaultValue: {
            id: 1,
            title: 'По часам'
          },
          setDefaultInFilters: 1,
          column: 1

        },
        graphType: {
          enable: true,
          placeholder: 'Группировка по: ',
          noGroupSelect: true,
          closeOnSelect: true,
          selectedKey: 'groupBy',
          data: [
              {
                id: 1,
                title: 'Нет группировки'
              },
              {
                id: 2,
                title: 'Сервис'
              },
              {
                id: 3,
                title: 'Страна'
              },
              {
                id: 4,
                title: 'Клиент'
              },
              {
                id: 5,
                title: 'Провайдер'
              },
          ],
          defaultValue: {
            id: 1,
            title: 'Нет группировки'
          },
          setDefaultInFilters: 1,
          column: 1

        },
        services: {
          enable: true,
          multiple: true,
          getDataLink: '/services/getList',
          placeholder: 'Сервисы',
          viewLabel: 'titleWithId',
          column: 2
        },
        countries: {
          enable: true,
          multiple: true,
          getDataLink: '/countries/getList',
          placeholder: 'Страны',
          viewLabel: 'titleWithId',
          column: 2,
        },
        users: {
          enable: true,
          multiple: true,
          getDataLink: '/users/getUsers',
          placeholder: 'Клиенты',
          specifyKeyForLabel: 'name',
          viewLabel: 'titleWithId',
          column: 3
        },
        providers: {
          enable: true,
          multiple: true,
          getDataLink: '/providers/getList',
          placeholder: 'Провайдеры',
          viewLabel: 'titleWithId',
          column: 3
        },
      }},on:{"set-filtered-list":_vm.setFilteredList}}),_vm._v(" "),_c('div',{staticClass:"wrapperCanvas"},[(_vm.loaded)?_c('LineChart',{attrs:{"chart-data":_vm.chartData}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }