<template>
  <div>
    <base-table
        :columns="this.columns"
        :wrapperFiltersData="this.wrapperFiltersData"
        :addModalInputData="this.addModalInputData"
        :changeAllWithLoaderUrl="changeAllWithLoaderUrl"
        :changeItemWithLoaderMethod="changeItemWithLoaderMethod"
        :changeItemWithLoaderUrl="changeItemWithLoaderUrl"
        :changeAllWithLoaderMethod="changeAllWithLoaderMethod"
        deleteByIdUrl="/userPrices"
        deleteAllUrl="/userPrices/deleteMany"
        :render-list-to-show="this.listToShow"
    >
      <template v-slot:base-table-actions="props">
        <div class="topping__btns fx fx-end col-12">
          <label class="form__field mr-10">
            <input type="text" placeholder="Новая цена для всех в списке" v-model="newPriceModel" @keyup.enter="changeAllWithLoader({price: newPriceModel});  newPriceModel = null;"/>
          </label>
          <button class="btn-t btn--borderRed flex-center" @click="props.changeAllWithLoader({price: newPriceModel}); newPriceModel = null;">Обновить цену</button>
        </div>
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderRed flex-center" @click="props.toggleShowModalAdd()">Добавить</button>
          <button class="btn-t btn--borderRed flex-center" @click="props.deleteAll()">Удалить все в списке</button>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <div class="admin-table-element">
          <template v-if="props.column.field === 'service'">
            <img :src="props.row['service_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'country'">
            <img :src="props.row['country_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'delete'">
            <button class="btn btn-danger w-100" @click="props.deleteById(props.row['id'])">Delete</button>
          </template>
          <template v-else-if="props.column.field === 'price'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {price: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'do_not_change'">
            <button v-if="props.formattedRow[props.column.field]" class="btn btn-success w-90"
                    @click="props.changeItemWithLoader(props.row['id'], {do_not_change: false})">ДА
            </button>
            <button v-else class="btn btn-danger w-90"
                    @click="props.changeItemWithLoader(props.row['id'], {do_not_change: true})">НЕТ
            </button>
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from "../../../components/baseTable";

export default {
  name: "userPricesTable",
  components: {
    BaseTable,
  },
  data: function () {
    return {
      columns: [
        {
          label: 'Страна',
          field: 'country',
          width: '20%',
        },
        {
          label: 'Сервис',
          field: 'service',
          width: '15%',
        },
        {
          label: 'Клиенты',
          field: 'user',
          width: '30%',
        },
        {
          label: 'Цена',
          field: 'price',
          sortFn: 'sortNumber',
          width: '10%',
        },
        {
          label: 'Удалить',
          field: 'delete',
          width: '11%',
        },
        {
          label: 'Не менять',
          field: 'do_not_change',
          width: '11%',
        },
      ],
      addModalInputData: {
        addModalTitle: 'Добавить индивидуальную цену',
        addModalUrl: '/userPrices/addMany',
        items: {
          services: {
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Выберите сервис',
            viewLabel: 'titleWithId',
          },
          countries: {
            multiple: true,
            getDataLink: '/countries/getList',
            placeholder: 'Выберите страну',
            viewLabel: 'titleWithId',
          },
          users: {
            multiple: true,
            getDataLink: '/users/getUsers',
            specifyKeyForLabel: 'name',
            placeholder: 'Выберите клиентов',
            viewLabel: 'titleWithId',
          },
          price: {
            simpleInputType: 'text',
            placeholder: 'Индивидуальная цена',
            selectedKey: 'price',
          },
        }
      },
      wrapperFiltersData: {
        title: "Индивидуальные цены клиентов",
        filtersUrl: "/userPrices/getList",
        items: {
          countries: {
            enable: true,
            multiple: true,
            getDataLink: '/countries/getList',
            placeholder: 'Страны',
            viewLabel: 'titleWithId',
            column: 1,
          },
          services: {
            enable: true,
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Сервисы',
            viewLabel: 'titleWithId',
            column: 2
          },
          users: {
            enable: true,
            multiple: true,
            getDataLink: '/users/getUsers',
            placeholder: 'Клиенты',
            specifyKeyForLabel: 'name',
            viewLabel: 'titleWithId',
            column: 3
          },
        }
      },
      changeAllWithLoaderUrl: '/userPrices/editMany',
      changeAllWithLoaderMethod: 'patch',
      changeItemWithLoaderUrl: '/userPrices',
      changeItemWithLoaderMethod: 'patch',
      newPriceModel: null,
    };
  },

  methods: {
    listToShow(list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          service: elem['service_variation']['service']['title'] + ' (' + elem['service_variation']['service']['id'] + ')',
          country: elem['service_variation']['country']['title'] + ' (' + elem['service_variation']['country']['id'] + ')',
          price: Number(elem['price']).toFixed(2),
          service_img_path: elem['service_variation']['service']['img_path'],
          country_img_path: elem['service_variation']['country']['img_path'],
          user: elem['user']['name'] + ' (' + elem['user']['id'] + ')',
          do_not_change: elem['do_not_change'],
        });
      }
      return data;
    },
  },
}
</script>

<style scoped>

</style>
