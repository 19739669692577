<template>
  <div>
    <v-notification>
    </v-notification>
    <Modal
        v-show="showModalAdd"
        :show="showModalAdd"
        :scrollable="false"
        header-id="modalHeader"
        body-id="modalBody"
        @close="toggleShowModalAdd">
      <template #header>
        {{ addModalTitle }}
      </template>
      <template #body>
        <instance-input-select
            v-for="(addInput, addInputName) in prepareDataToAdd"
            :key="addInputName"
            :index="addInputName"
            :object="addInput"
            @add-filter-id-arr-single="addFilterId"
            @add-filter-data-single="addFilterSingle"
            @add-filter-ids-arr="addFilterIdsArray"
            @remove-filter-id="removeFilterId"
            @remove-filter-ids-arr="removeFilterIdsArray">
        </instance-input-select>
        <button class="col-12 btn-t btn--borderRed flex-center" @click="addSelected">Добавить</button>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from './modal';
import BaseAdminMixin from "../admin/components/mixins/BaseAdminMixin";
import AddRemoveSelectedMixin from './mixins/AddRemoveSelectedMixin'
import InstanceInputSelect from "./instanceInputSelect";

export default {
  name: "modalAdd",
  mixins: [
    BaseAdminMixin,
    AddRemoveSelectedMixin
  ],
  components: {
    Modal,
    InstanceInputSelect
  },
  props: {
    addModalInputData: {
      type: Object,
      default: {}
    },
    addModalUrl: {
      type: String,
      required: true
    }
  },
  async mounted() {
    await this.parseInputData();
  },
  data() {
    return {
      showModalAdd: false,
      addModalItems: {},
      selected: {},
      addModalTitle: 'Title',
      addModalMethod: 'put',
    }
  },
  methods: {
    toggleShowModalAdd() {
      this.showModalAdd = !this.showModalAdd;
    },
    parseInputData() {
      if (this.addModalInputData.addModalTitle) {
        this.addModalTitle = this.addModalInputData.addModalTitle;
      }
      if (this.addModalInputData.addModalMethod) {
        this.addModalMethod = this.addModalInputData.addModalMethod;
      }
      if (this.addModalInputData.items) {
        this.addModalItems = this.addModalInputData.items;
      }
    },
    clearObjects(addParams) {
      let data = {};
      for (let filter in addParams) {
        if (Array.isArray(addParams[filter]) && addParams[filter].length) {
          data[filter] = addParams[filter];
        } else if (addParams[filter] !== null) {
          data[filter] = addParams[filter];
        }
      }
      return data;
    },
    addSelected() {
      this.timeout = setTimeout(
          () => this.doWithStopRequest(
              () => this.add(), 'Adding'),
          1
      );
    },
    async add() {
      let response = await this.sendRequest(
          this.addModalUrl,
          this.addModalMethod,
          this.clearObjects(this.selected),
      );


      if (response) {
        this.$emit('insert-added-data', response['data']);
        await this.alertMessage('Successfully added');
        this.toggleShowModalAdd();
      }
    },
  },
  computed: {
    prepareDataToAdd() {
      let data = {};
      for (let elem in this.addModalItems) {
        let obj = this.addModalItems[elem];
        if (!obj.placeholder) {
          obj.placeholder = '';
        }
        if (!obj.selectedKey) {
          obj.selectedKey = elem + 'Ids';
        }
        data[elem] = obj;

      }
      return data;
    }
  }
}
</script>

<style scoped>

</style>
