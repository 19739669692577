<template>
  <div>
    <v-notification>
    </v-notification>
    <template v-if="listToShow.length">
      <vue-good-table
        title="Telegram"
        :columns="columns"
        :rows="listToShow"
        :lineNumbers="false"
        :sort-options="{
              enabled: true,
            }"
        :style-class="'vgt-table admin-table'"
        :globalSearch="true"
        :pagination-options="{
              enabled: true,
              perPage: perPage
            }"
      >
        <template v-slot:table-row="props">
          <div class="admin-table-element" style="font-size: 16px">
            <template>
              <a :href="hrefUrl+props.formattedRow.id">{{ props.formattedRow[props.column.field] }}</a>
            </template>
          </div>
        </template>
        <template v-slot:pagination-bottom="props">
          <custom-pagination
            :total="props.total"
            :pageChanged="props.pageChanged"
            :perPageChanged="props.perPageChanged"
            :perPage="perPage"
          >
          </custom-pagination>
        </template>
      </vue-good-table>
    </template>
  </div>
</template>
<script>
import BaseAdminMixin from "../../mixins/BaseAdminMixin";
import CustomPagination from "../../../../components/customPagination";
import Multiselect from "vue-multiselect";

export default {
  name: "telegram",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect,
  },
  props:[
    'listToShow',
    'hrefUrl'
  ],
  data: function () {
    return {
      columns: [
        {
          label: 'Id',
          field: 'id',
          width: '6%',
        },
        {
          label: 'Название',
          field: 'name',
          width: '14%',
        },
        {
          label: 'Краткое описание',
          field: 'description',
          width: '20%',
        },
      ],
      telegramListData: [],
      selected: [],
      perPage: 50,
      languageSelect: [],
      newHrefUrl: ''
    }
  },
  mounted() {
    this.newHrefUrl = this.hrefUrl
  }
}
</script>
