<template>
  <div>
    <base-table
      :render-list-to-show="this.listToShow"
      :per-page="15"
      :wrapper-filters-data="this.wrapperFiltersData"
      :columns="this.columns"
    >
      <template v-slot:base-table-row="props">
        <template v-if="props.column.field === 'country'">
          <img :src="props.row['country_img_path']" alt="" width="20px">
          {{ props.formattedRow[props.column.field] }}
        </template>
        <template v-else-if="props.column.field === 'service'">
          <img :src="props.row['service_img_path']" alt="" width="20px">
          {{ props.formattedRow[props.column.field] }}
        </template>
      </template>
    </base-table>
  </div>
</template>

<script>
import CustomPagination from "../../../components/customPagination.vue";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../../../assets/css/multiselectCustomization.css';
import BaseAdminMixin from "./../mixins/BaseAdminMixin";
import BaseTable from "../../../components/baseTable.vue";

export default {
  name: "tableTemplate",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect,
    BaseTable
  },
  data: function () {
    return {
      list: [],
      filteredList: [],
      showFilters: true,
      perPage: 25,
      timeout: null,
      wrapperFiltersData: {
        title: "Логи запросов",
        filtersUrl: "/requests/getList",
        filtersUrlMethod: "get",
        items: {
          users: {
            enable: true,
            multiple: true,
            getDataLink: '/users/getUsers',
            placeholder: 'Клиенты',
            specifyKeyForLabel: 'name',
            viewLabel: 'titleWithId',
            column: 1
          },
          country: {
            enable: true,
            multiple: true,
            getDataLink: '/countries/getList',
            placeholder: 'Выберите страну',
            viewLabel: 'titleWithId',
            selectedKey: 'countryIds',
            column: 2
          },
          service: {
            enable: true,
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Выберите сервис',
            viewLabel: 'titleWithId',
            selectedKey: 'serviceIds',
            column: 2
          },
          number: {
            enable: true,
            simpleInputType: 'text',
            placeholder: 'Поиск по номеру телефона',
            selectedKey: 'number',
            column: 3
          },
          activation: {
            enable: true,
            simpleInputType: 'text',
            placeholder: 'Поиск по ID активации',
            selectedKey: 'activation',
            column: 3
          },
          requestLogType: {
            enable: true,
            getDataLink: "/requests/getTypes",
            placeholder: 'Поиск по статусу',
            viewLabel: 'titleWithId',
            selectedKey: 'requestLogType',
            column: 1
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
              {
                id: 1,
                title: 'За последние 20 минут'
              },
              {
                id: 2,
                title: 'За последний час'
              },
              {
                id: 10,
                title: 'За последние 2 минуты'
              },
              {
                id: 8,
                title: 'За последний 5 минут'
              },
              {
                id: 9,
                title: 'За последние 10 минут'
              },
            ],
            column: 1,
          },
        },
      },
      columns: [
        {
          label: 'Активация',
          field: 'phone_activation_id',
          sortable: true,
          width: '10%',
        },
        {
          label: 'Страна',
          field: 'country',
          sortable: true,
          width: '11%',
        },
        {
          label: 'Сервис',
          field: 'service',
          sortable: true,
          width: '11%',
        },
        {
          label: 'мс',
          type: 'number',
          field: 'request_time',
          sortable: true,
          width: '7%'
        },
        {
          label: 'Дата',
          field: 'created_at',
          sortable: true,
          width: '15%',
        },
        {
          label: 'Клиент',
          field: 'user',
          sortable: false,
          width: '10%'
        },
        {
          label: 'Статус',
          field: 'status',
          sortable: false,
          width: '5%'
        },
        {
          label: 'Тип',
          field: 'request_type',
          sortable: false,
          width: '10%',
        },
        {
          label: 'Запрос',
          field: 'request_data',
          sortable: false,
          width: '10%',
        },
        {
          label: 'Ответ',
          field: 'response_data',
          sortable: false,
          width: '11%',
        },
      ],
    };
  },

  methods: {
    setFilteredList(response) {
      this.filteredList = response;
    },
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        let user = 'Unknown user';
        if (elem.hasOwnProperty('user') && elem['user']) {
          user = elem['user']['login']
        }
        data.push({
          created_at: elem['created_at'],
          user: user,
          request_type: elem['request_type']['description'],
          status: elem['status'],
          request_time: elem['request_time'],
          country: elem['country'] ? elem['country']['title'] : null,
          country_img_path: elem['country'] ? elem['country']['img_path'] : null,
          service: elem['service'] ? elem['service']['title'] : null,
          service_img_path: elem['service'] ? elem['service']['img_path'] : null,
          phone_activation_id: elem['phone_activation_id'],
          response_data: elem['response_data'],
          request_data: elem['request_data'],
        });
      }
      return data;
    },
  },
}
</script>
