<template>
  <div>
    <base-table
      :columns="this.columns"
      :wrapperFiltersData="this.wrapperFiltersData"
      :perPage="50"
      :render-list-to-show="this.listToShow"
      :enable-save-csv="true"
      saveCsvUrl="/statistic/topUp/downloadCsv"
    >
      <template v-slot:base-table-row="props">
        <div class="admin-table-element" style="font-size: 16px">
          <template v-if="props.column.field === 'change'">
                            <span :class="props.row[props.column.field] > 0 ? 'green' : 'red'">{{
                                props.row[props.column.field]
                              }}<br></span>
          </template>
          <template v-else>
            <span>{{ props.formattedRow[props.column.field] }}</span>
          </template>
        </div>
      </template>
      <template v-slot:base-table-actions-bottom>
        <div v-show="detailStatistic.show" slot="table-actions-bottom">
          <div class="wrapper">
            <h3>Суммарно</h3>
            <div class="sum-data">
              <div>Всего пополнений: {{new Intl.NumberFormat().format(detailStatistic.totalTopUpAmount)}}</div>
            </div>
          </div>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from "../../../components/baseTable";

export default {
  name: "topUpStatistic",
  components: {
    BaseTable
  },
  data: function () {
    return {
      detailStatistic: {
        totalTopUpAmount: 0,
        show: false,
      },
      wrapperFiltersData: {
        title: "Пополнения пользователей",
        filtersUrl: "/statistic/topUp/getList",
        startWithRequest: true,
        items: {
          date: {
            enable: true,
            column: 1,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
              {
                id: 1,
                title: 'За последние 20 минут'
              },
              {
                id: 2,
                title: 'За последний час'
              },
              {
                id: 3,
                title: 'За последний день'
              },
              {
                id: 4,
                title: 'За последние 7 дней'
              },
              {
                id: 5,
                title: 'За последние 30 дней'
              },
            ],
            column: 1,
            defaultValue: {
              id: 1,
              title: 'За последние 20 минут'
            },
            setDefaultInFilters: 1
          },
          statuses: {
            enable: true,
            multiple: true,
            selectedKey: 'types',
            getDataLink: '/statistic/topUp/getStatusesList',
            placeholder: 'Статусы',
            specifyKeyForLabel: 'description',
            column: 3
          },
          users: {
            enable: true,
            multiple: true,
            getDataLink: '/users/getUsers',
            placeholder: 'Клиенты',
            specifyKeyForLabel: 'login',
            viewLabel: 'titleWithId',
            column: 2
          },
          types: {
            enable: true,
            multiple: true,
            selectedKey: 'types',
            getDataLink: '/statistic/topUp/getTypesList',
            placeholder: 'Типы',
            specifyKeyForLabel: 'description',
            column: 3
          },
        }
      },
      columns: [
        {
          label: 'Дата',
          field: 'created_at',
          width: '15%',
        },
        {
          label: 'Пользователь',
          field: 'user',
          width: '15%',
          sortable: false,
        },
        {
          label: 'Описание',
          field: 'description',
          width: '30%',
          sortable: false,
        },
        {
          label: 'Сумма',
          field: 'change',
          type: 'number',
          width: '15%',
          sortFn: 'sortNumber'
        },
        {
          label: 'Сумма валюты',
          field: 'currency_change',
          type: 'number',
          width: '15%',
          sortFn: 'sortNumber'
        },
        {
          label: 'Валюта',
          field: 'currency',
          width: '15%',
          sortFn: 'sortNumber'
        },
        {
          label: 'Outcome amount',
          field: 'outcome_amount',
          width: '15%',
          sortFn: 'sortNumber'
        },
      ]
    };
  },
  methods: {
    listToShow(list) {
      this.clearSumElement();
      let result = [];
      for (let elem of list) {
        this.detailStatistic.totalTopUpAmount += parseFloat(elem['sum']);
        this.detailStatistic.show = true;
        result.push({
          created_at: elem['created_at'],
          currency_change: elem['pay_amount'],
          currency: elem['pay_currency'],
          outcome_amount: elem['pay_amount_without_commission'],
          change: elem['sum'],
          description: elem['type']['description'],
          user: elem['user'] ? elem['user']['login'] : '',
        });
      }
      return result;
    },

    clearSumElement() {
      this.detailStatistic['totalTopUpAmount'] = 0;
      this.detailStatistic['show'] = false;
    },
  }
}
</script>

<style scoped>
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
  }
  .sum-data {
    margin-left: calc(100% - 90%);
    display: flex;
    justify-content: space-between;
  }
  .sum-data div {
    margin: 0 15px;
  }
</style>
