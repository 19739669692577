<template>
  <div>
    <v-notification></v-notification>
    <Modal
        v-show="showModalAdd"
        :show="showModalAdd"
        :scrollable="true"
        header-id="modalHeader"
        body-id="modalBody"
        @close="showModalAdd = false">
      >
      <template #header>
        Добавить sitemap
        <vue-spinner class="absolute" color="#CE0000" v-if="showLoader"></vue-spinner>
      </template>
      <template #body>
        <label class="form__field">
          <input type="text" placeholder="URL" v-model="paramsToAdd.url"/>
        </label>
        <label class="form__field">
          <input type="text" placeholder="SEO ключи" v-model="paramsToAdd.seo_keys"/>
        </label>
        <label class="form__field">
          <input type="text" placeholder="SEO заголовок" v-model="paramsToAdd.seo_title"/>
        </label>
        <label class="form__field">
          <input type="text" placeholder="SEO описание" v-model="paramsToAdd.seo_description"/>
        </label>
        <button class="col-12 btn-t btn--borderRed flex-center" @click="add">Добавить</button>
      </template>
    </Modal>
    <base-table
        :columns="this.columns"
        :wrapperFiltersData="this.wrapperFiltersData"
        :changeItemWithLoaderMethod="changeItemWithLoaderMethod"
        :changeItemWithLoaderUrl="changeItemWithLoaderUrl"
        :perPage="100"
        :render-list-to-show="this.listToShow"
        ref="baseTable"
    >
      <template v-slot:base-table-row="props">
        <div class="admin-table-element" style="font-size: 13px">
          <template v-if="props.column.field === 'seo_keys'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {seo_keys: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'seo_title'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {seo_title: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'seo_description'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {seo_description: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
      <template v-slot:base-table-actions>
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderRed flex-center" @click="showModalAdd = !showModalAdd">Добавить</button>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from "../../../components/baseTable";
import Modal from '../../../components/modal';
import BaseAdminMixin from "../../../admin/components/mixins/BaseAdminMixin";

export default {
  name: "sitemap",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    BaseTable,
    Modal
  },
  data: function () {
    return {
      wrapperFiltersData: {
        title: "Sitemap",
        filtersUrl: '/sitemap/getList',
        startWithRequest: true
      },
      columns: [
        {
          label: 'URL',
          field: 'url',
          width: '35%',
        },
        {
          label: 'SEO ключи',
          field: 'seo_keys',
          width: '10%',
        },
        {
          label: 'SEO заголовок',
          field: 'seo_title',
          width: '20%',
        },
        {
          label: 'SEO описание',
          field: 'seo_description',
          width: '20%',
        }
      ],
      changeItemWithLoaderMethod: 'post',
      changeItemWithLoaderUrl: '/sitemap/edit',
      showModalAdd: false,
      paramsToAdd: {
        url: null,
        seo_keys: null,
        seo_title: null,
        seo_description: null,
      },
    };
  },
  methods: {
    listToShow(list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          url: elem['url'],
          seo_keys: elem['seo_keys'],
          seo_title: elem['seo_title'],
          seo_description: elem['seo_description'],
        });
      }
      return data;
    },
    async add()
    {
      let response = await this.sendRequest(
          '/sitemap/add',
          'post',
          this.clearObjects(this.paramsToAdd),
      );

      if (response) {
        this.filteredList.unshift(...response['data']);
        await this.alertMessage('Service variations successfully added');
        this.showModalAdd = false;
      }
    },
    clearObjects(filters)
    {
      let data = {};
      for (let filter in filters) {
        if (Array.isArray(filters[filter]) && filters[filter].length) {
          data[filter] = filters[filter];
        } else if (filters[filter] !== null) {
          data[filter] = filters[filter];
        }
      }
      return data;
    },
  },
}
</script>
