<template>
  <div>
    <base-table
        :columns="this.columns"
        :wrapperFiltersData="this.wrapperFiltersData"
        :addModalInputData="this.addModalInputData"
        deleteByIdUrl="/userLockedServices"
        deleteAllUrl="/userLockedServices/deleteMany"
        :perPage="25"
        :render-list-to-show="this.listToShow"
    >

      <template v-slot:base-table-actions="props">
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderRed flex-center" @click="props.toggleShowModalAdd">Добавить</button>
          <button class="btn-t btn--borderRed flex-center" @click="props.deleteAll">Удалить все в списке</button>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <div class="admin-table-element">
          <template v-if="props.column.field === 'service'">
            <img :src="props.row['service_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'country'">
            <img :src="props.row['country_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'delete'">
            <button class="btn btn-danger w-100" @click="props.deleteById(props.row['id'])">Delete</button>
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from "../../../components/baseTable";

export default {
  name: "userLockedServicesTable",
  components: {
    BaseTable,
  },
  data: function () {
    return {
      columns: [
        {
          label: 'Провайдер',
          field: 'provider',
          width: '15%',
        },
        {
          label: 'Страна',
          field: 'country',
          width: '20%',
        },
        {
          label: 'Сервис',
          field: 'service',
          width: '15%',
        },
        {
          label: 'Клиент',
          field: 'user',
          width: '30%',
        },
        {
          label: 'Удалить',
          field: 'delete',
          width: '11%',
        },
      ],
      wrapperFiltersData: {
        title: "Заблокированные сервисы клиентов",
        filtersUrl: "/userLockedServices/getList",
        items: {
          countries: {
            enable: true,
            multiple: true,
            getDataLink: '/countries/getList',
            placeholder: 'Страны',
            viewLabel: 'titleWithId',
            column: 1,
          },
          users: {
            enable: true,
            multiple: true,
            getDataLink: '/users/getUsers',
            placeholder: 'Клиенты',
            specifyKeyForLabel: 'name',
            viewLabel: 'titleWithId',
            column: 1
          },
          services: {
            enable: true,
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Сервисы',
            viewLabel: 'titleWithId',
            column: 2
          },
          providers: {
            enable: true,
            multiple: true,
            getDataLink: '/providers/getList',
            placeholder: 'Провайдеры',
            viewLabel: 'titleWithId',
            column: 3
          },
        }
      },
      addModalInputData: {
        addModalTitle: 'Заблокировать сервисы клиентам',
        addModalUrl: '/userLockedServices/addMany',
        addModalMethod: 'put',
        items: {
          services: {
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Выберите сервис',
            viewLabel: 'titleWithId',
          },
          countries: {
            multiple: true,
            getDataLink: '/countries/getList',
            placeholder: 'Выберите страну',
            viewLabel: 'titleWithId',
          },
          providers: {
            multiple: true,
            getDataLink: '/providers/getList',
            placeholder: 'Выберите провайдера',
            viewLabel: 'titleWithId',
          },
          users: {
            multiple: true,
            getDataLink: '/users/getUsers',
            specifyKeyForLabel: 'name',
            placeholder: 'Выберите клиентов',
            viewLabel: 'titleWithId',
          },
        }
      },
    };
  },
  methods: {
    listToShow(list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          service: elem['provider_service_variation']['service_variation']['service']['title'] + ' (' + elem['provider_service_variation']['service_variation']['service']['id'] + ')',
          country: elem['provider_service_variation']['service_variation']['country']['title'] + ' (' + elem['provider_service_variation']['service_variation']['country']['id'] + ')',
          service_img_path: elem['provider_service_variation']['service_variation']['service']['img_path'],
          country_img_path: elem['provider_service_variation']['service_variation']['country']['img_path'],
          provider: elem['provider_service_variation']['provider']['title'] + ' (' + elem['provider_service_variation']['provider']['id'] + ')',
          user: elem['user']['name'] + ' (' + elem['user']['id'] + ')',
        });
      }
      return data;
    },
  },
}
</script>

<style scoped>

</style>
