<script>
import VueSpinner from "vue-spinner/src/PulseLoader";

export default {
  components: {
    VueSpinner
  },
  name: "BaseLoaderMixin",
  data: function () {
    return {
      showLoader: false,
    }
  },
  methods: {
    async doWithLoader(callback) {
      this.showLoader = true;
      await new Promise(r => setTimeout(r, 1));
      await callback();
      this.showLoader = false;
    },
  },
}
</script>

<style scoped>

</style>