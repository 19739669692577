<template>
  <div>
    <wrapper-filters
        title="Свободные активации"
        filtersUrl="/caughtPhoneActivations/getList"
        filtersUrlMethod="get"
        :startWithRequest="true"
        :setEnabledFilters="{
          date: {
            enable: true,
            column: 1,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
                {
                  id: 1,
                  title: 'За последние 20 минут'
                },
                {
                  id: 2,
                  title: 'За последний час'
                },
                {
                  id: 3,
                  title: 'За последний день'
                },
                {
                  id: 4,
                  title: 'За последние 7 дней'
                },
                {
                  id: 5,
                  title: 'За последние 30 дней'
                },
            ],
            defaultValue: {
              id: 2,
              title: 'За последний час'
            },
            column: 1,
          },
          countries: {
            enable: true,
            multiple: true,
            getDataLink: '/countries/getList',
            placeholder: 'Страны',
            viewLabel: 'titleWithId',
            column: 2,
          },
          services: {
            enable: true,
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Сервисы',
            viewLabel: 'titleWithId',
            column: 2
          },
          statuses: {
            enable: true,
            multiple: true,
            selectedKey: 'statuses',
            getDataLink: '/phoneActivationStatuses/getList',
            placeholder: 'Статусы',
            specifyKeyForLabel: 'description',
            column: 3
          },
          providers: {
            enable: true,
            multiple: true,
            getDataLink: '/providers/getList',
            placeholder: 'Провайдеры',
            viewLabel: 'titleWithId',
            column: 3
          },
        }"
        @set-filtered-list="setFilteredList"
    >
    </wrapper-filters>
    <!-- Table -->
    <vue-good-table
        title="Service variations"
        :columns="columns"
        :rows="listToShow"
        :lineNumbers="false"
        :sort-options="{
              enabled: true,
            }"
        :style-class="'vgt-table admin-table'"
        :globalSearch="true"
        :pagination-options="{
              enabled: true,
              perPage: perPage
            }"
    >
      <template v-slot:table-row="props">
        <div class="admin-table-element" style="font-size: 16px">
          <template v-if="props.column.field === 'service'">
            <img :src="props.row['service_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'country'">
            <img :src="props.row['country_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
      <template v-slot:pagination-bottom="props">
        <custom-pagination
            :total="props.total"
            :pageChanged="props.pageChanged"
            :perPageChanged="props.perPageChanged"
            :perPage="perPage"
        >
        </custom-pagination>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import CustomPagination from "../../../components/customPagination";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../../../assets/css/multiselectCustomization.css';
import BaseAdminMixin from "../mixins/BaseAdminMixin";
import Modal from '../../../components/modal';
import WrapperFilters from "../../../components/wrapperFilters"

export default {
  name: "caughtPhoneActivationsTable",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect,
    WrapperFilters,
  },
  data: function () {
    return {
      filteredList: [],
      columns: [
        {
          label: '#',
          field: 'originalIndex',
          width: '4%',
          sortable: false
        },
        {
          label: 'Страна',
          field: 'country',
        },
        {
          label: 'Сервис',
          field: 'service',
          width: '15%',
        },
        {
          label: 'Провайдеры',
          field: 'provider',
          width: '15%',
        },
        {
          label: 'Статус',
          field: 'status',
          width: '10%',
        },
        {
          label: 'Номер',
          field: 'number',
          width: '10%',
          sortable: false,
        },
        {
          label: 'Опт  ',
          field: 'wholesale_price',
          width: '5%',
          sortable: true,
          sortFn: this.sortNumber,
        },
        {
          label: 'Дата',
          field: 'created_at',
          width: '13%',
        },
      ],
      filters: {
        dateFrom: null,
        dateTo: null,
      },
      showFilters: true,
      perPage: 50,
      timeout: null,
    };
  },

  methods: {
    setFilteredList(response) {
      this.filteredList = response
    },

    getIndexByProp(list, propKey, propValue)
    {
      for (let key in list) {
        if (list[key][propKey] == propValue) {
          return key;
        }
      }

      return null;
    },

    toggleShowModalAdd() {
      this.showModalAdd = !this.showModalAdd;
    },
  },
  computed: {
    listToShow: function () {
      let data = [];
      for (let elem of this.filteredList) {
        data.push({
          id: elem['id'],
          provider: elem['provider_service_variation']['provider']['title'] + ' (' + elem['provider_service_variation']['provider']['id'] + ')',
          country: elem['provider_service_variation']['service_variation']['country']['title'] + ' (' + elem['provider_service_variation']['service_variation']['country']['id'] + ')',
          country_img_path: elem['provider_service_variation']['service_variation']['country']['img_path'],
          service: elem['provider_service_variation']['service_variation']['service']['title'] + ' (' + elem['provider_service_variation']['service_variation']['service']['id'] + ')',
          service_img_path: elem['provider_service_variation']['service_variation']['service']['img_path'],
          status: elem['status']['description'],
          number: elem['phone'],
          price: Number(elem['price']).toFixed(2),
          wholesale_price: Number(elem['wholesale_price']).toFixed(2),
          created_at: elem['created_at'],
        });
      }
      return data;
    },
  },
}
</script>

<style scoped>

</style>
