<template>

</template>

<script>
import BaseSendRequestMixin from "../../../components/mixins/BaseSendRequestMixin";
import BaseMessagingMixin from "../../../components/mixins/BaseMessagingMixin";
import BaseLoaderMixin from "../../../components/mixins/BaseLoaderMixin";

export default {
  name: "BaseAdminMixin",
  mixins: [
      BaseSendRequestMixin,
      BaseMessagingMixin,
      BaseLoaderMixin
  ],
  methods:{
    sortNumber(a, b)
    {
      a = parseInt(a);
      b = parseInt(b)
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1
      }

      return 0;
    },
  }
}
</script>

<style scoped>

</style>
