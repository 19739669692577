<template>
  <div>
    <v-notification>
    </v-notification>
    <Modal
        v-show="showModalAdd"
        :show="showModalAdd"
        :scrollable="false"
        header-id="modalHeader"
        body-id="modalBody"
        @close="toggleShowModalAdd"
    >
      <template #header>
        Добавить новые потоки
      </template>
      <template #body>
        <multiselect
            v-model="selectedServicesToAdd"
            placeholder="Выберите сервис"
            label="title"
            track-by="id"
            :closeOnSelect="false"
            group-values="list"
            group-label="group"
            :group-select="true"
            :showLabels="false"
            :options="wrapperForAllSelected(serviceLabels)"
            :multiple="true"
            openDirection="bottom"
        ></multiselect>
        <multiselect
            v-model="selectedCountriesToAdd"
            placeholder="Выберите страну"
            label="title"
            track-by="id"
            :closeOnSelect="false"
            group-values="list"
            group-label="group"
            :group-select="true"
            :showLabels="false"
            :options="wrapperForAllSelected(countryLabels)"
            :multiple="true"
            openDirection="bottom"
        ></multiselect>
        <multiselect
            v-model="selectedProvidersToAdd"
            placeholder="Выберите провайдера"
            label="title"
            track-by="id"
            :closeOnSelect="false"
            group-values="list"
            group-label="group"
            :group-select="true"
            :showLabels="false"
            :options="wrapperForAllSelected(providerLabels)"
            :multiple="true"
            openDirection="bottom"
        ></multiselect>
        <label class="form__field">
          <input type="text" placeholder="Кол-во потоков" v-model="paramsToAdd['streams_count']"/>
        </label>
        <label class="form__field">
          <input type="text" placeholder="Пауза между запросами(мс) для всех" v-model="paramsToAdd['sleep_time']"/>
        </label>
        <button class="col-12 btn-t btn--borderRed flex-center" v-on:click="addMany">Добавить</button>
      </template>
    </Modal>
    <wrapper-filters
        title="Потоки получения активаций"
        filtersUrl="/phoneActivationCatchers/getList"
        filtersUrlMethod="get"
        :startWithRequest="true"
        :setEnabledFilters="{
          countries: {
            enable: true,
            multiple: true,
            getDataLink: '/countries/getList',
            placeholder: 'Страны',
            viewLabel: 'titleWithId',
            column: 1,
          },
          services: {
            enable: true,
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Сервисы',
            viewLabel: 'titleWithId',
            column: 2
          },
          providers: {
            enable: true,
            multiple: true,
            getDataLink: '/providers/getList',
            placeholder: 'Провайдеры',
            viewLabel: 'titleWithId',
            column: 3
          },
          onlyActive:{
            enable: true,
            filterKey: 'onlyActive',
            upperSpanText: 'Только не активные: ',
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            placeholder: 'active',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            column: 3
          },
        }"
        @set-filtered-list="setFilteredList"
    >
    </wrapper-filters>
    <!-- Table -->
    <vue-good-table
        title="Service variations"
        :columns="columns"
        :rows="listToShow"
        :lineNumbers="false"
        :sort-options="{
              enabled: true,
            }"
        :style-class="'vgt-table admin-table'"
        :globalSearch="true"
        :pagination-options="{
              enabled: true,
              perPage: perPage
            }"
    >
      <template v-slot:table-actions>
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderRed flex-center" v-on:click="toggleShowModalAdd">Добавить</button>
          <button class="btn-t btn--borderRed flex-center" v-on:click="changeAllWithLoader({is_active: true})">Активировать все</button>
          <button class="btn-t btn--borderRed flex-center" v-on:click="changeAllWithLoader({is_active: false})">Деактивировать все</button>
          <button class="btn-t btn--borderRed flex-center" v-on:click="deleteAll({is_active: false})">Удалить все</button>
          <label class="form__field mr-10">
            <input type="text" placeholder="Кол-во потоков для всех" v-model="newRatioModel" @keyup.enter="changeAllWithLoader({streams_count: newRatioModel});  newRatioModel = null;"/>
          </label>
          <label class="form__field mr-10">
            <input type="text" placeholder="Пауза между запросами(мс) для всех" v-model="newSleepTimeModel" @keyup.enter="changeAllWithLoader({sleep_time: newSleepTimeModel});  newSleepTimeModel = null;"/>
          </label>
        </div>
      </template>
      <template v-slot:table-row="props">
        <div class="admin-table-element">
          <template v-if="props.column.field === 'service'">
            <img :src="props.row['service_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'country'">
            <img :src="props.row['country_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'streams_count'">
            <input @keyup.enter="changeItemWithLoader(props.row['id'], {streams_count: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'sleep_time'">
            <input @keyup.enter="changeItemWithLoader(props.row['id'], {sleep_time: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'is_active'">
            <button v-if="props.formattedRow[props.column.field]" class="btn btn-success w-100" @click="changeItemWithLoader(props.row['id'], {is_active: false})">ON</button>
            <button v-else class="btn btn-danger w-100" @click="changeItemWithLoader(props.row['id'], {is_active: true})">OFF</button>
          </template>
          <template v-else-if="props.column.field === 'delete'">
            <button class="btn btn-danger w-100" @click="deleteById(props.row['id'])">Delete</button>
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
      <template v-slot:pagination-bottom="props">
        <custom-pagination
            :total="props.total"
            :pageChanged="props.pageChanged"
            :perPageChanged="props.perPageChanged"
            :perPage="perPage"
        >
        </custom-pagination>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import CustomPagination from "../../../components/customPagination";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../../../assets/css/multiselectCustomization.css';
import BaseAdminMixin from "../mixins/BaseAdminMixin";
import Modal from '../../../components/modal';
import WrapperFilters from "../../../components/wrapperFilters"

export default {
  name: "phoneActivationCatchersTable",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect,
    Modal,
    WrapperFilters
  },
  data: function () {
    return {
      filteredList: [],
      columns: [
        {
          label: 'Провайдер  ',
          field: 'provider',
          width: '13%',
          html: true
        },
        {
          label: 'Страна  ',
          field: 'country',
          width: '16%',
          html: true
        },
        {
          label: 'Сервис  ',
          field: 'service',
          width: '13%',
          html: true
        },
        {
          label: 'Кол-во потоков  ',
          field: 'streams_count',
          sortFn: this.sortNumber,
          html: true,
          width: '6%',
        },
        {
          label: 'Пауза (мс)  ',
          field: 'sleep_time',
          sortFn: this.sortNumber,
          html: true,
          width: '6%',
        },
        {
          label: 'Кол-во свободных активаций  ',
          field: 'caught_phones_count',
          sortFn: this.sortNumber,
          html: true,
          width: '9%',
        },
        {
          label: 'Кол-во закрыто (за 20 мин) ',
          field: 'count_canceled',
          sortFn: this.sortNumber,
          html: true,
          width: '9%',
        },
        {
          label: 'Активность⠀⠀',
          field: 'is_active',
          width: '9%',
        },
        {
          label: 'Удалить⠀⠀',
          field: 'delete',
          width: '9%',
        },
      ],
      filters: {},
      paramsToAdd: {
        countriesIds: null,
        servicesIds: null,
        providersIds: null,
        streams_count: null,
        sleep_time: null,
      },
      showFilters: true,
      perPage: 70,
      newPriceModel: null,
      newRatioModel: null,
      newSleepTimeModel: null,
      timeout: null,
      selectedCountriesToAdd: [],
      selectedServicesToAdd: [],
      selectedProvidersToAdd: [],
      countries: [],
      services: [],
      providers: [],
      showModalAdd: false,
    };
  },

  async mounted() {
    await this.updateSelectorFilters();
  },

  methods: {
    setFilteredList(response) {
      this.filteredList = response;
    },

    async addMany()
    {
      let response = await this.sendRequest(
          '/phoneActivationCatchers/addMany',
          'put',
          this.clearObjects(this.paramsToAdd),
      );

      if (response) {
        this.filteredList.unshift(...response['data']);
        await this.alertMessage('Providers service variations successfully added');
        this.toggleShowModalAdd();
      }
    },

    async changeItemWithLoader(id, params)
    {
      let index = this.getIndexByProp(this.filteredList, 'id', id);
      let oldObj = this.filteredList[index];
      let newObj = Object.assign(this.filteredList[index], params);
      this.filteredList[index] = newObj
      let self = this;
      await this.doWithStopRequest(() => self.doWithLoader(async () => {
        let response = await this.sendRequest(
            '/phoneActivationCatchers',
            'patch',
            newObj
        );

        if (response) {
          this.filteredList[index] = newObj;
          await this.alertMessage(
              'Item has been changed',
              'info'
          );
        } else {
          this.filteredList[index] = oldObj;
        }
      }), 'Изменение сервиса')
    },
    async deleteById(id)
    {
      let self = this;
      await this.doWithStopRequest(() => self.doWithLoader(async () => {
        let response = await this.sendRequest(
          '/phoneActivationCatchers/delete',
          'delete',
          {
            id: id
          }
        );

        if (response) {
          let index = this.getIndexByProp(this.filteredList, 'id', id);
          this.filteredList.splice(index, 1);
          await this.alertMessage(
            'Item has been deleted',
            'info'
          );
        }
      }), 'Удаление потока')
    },
    async deleteAll()
    {
      let self = this;
      await this.doWithStopRequest(() => self.doWithLoader(async () => {
        let ids = this.filteredList.map(function (elem) {
          return elem['id'];
        });

        if (confirm('Вы уверены что хотите удалить ' + ids.length + ' активаций?')) {
          let response = await this.sendRequest(
            '/phoneActivationCatchers/deleteMany',
            'delete',
            {
              ids: ids
            }
          );

          if (response) {
            this.filteredList = [];
            await this.alertMessage(
              'Items have been deleted',
              'info'
            );
          }
        }
      }), 'Удаление блокировок')
    },
    async changeAllWithLoader(data)
    {
      let self = this;
      await this.doWithStopRequest(() => self.doWithLoader(async () => {
        let filteredServiceVariationsIds = this.filteredList.map(function (elem) {
          return elem['id'];
        });

        if (confirm('Вы уверены что хотите поменять ' + filteredServiceVariationsIds.length + ' позиций?')) {
          let params = data;
          params['ids'] = filteredServiceVariationsIds;
          let response = await this.sendRequest(
              '/phoneActivationCatchers/editMany',
              'patch',
              params
          );

          if (response) {
            this.filteredList = this.filteredList.map(function (elem) {
              elem = Object.assign(elem, data);
              return elem;
            });
            await this.alertMessage(
                'Items have been changed',
                'info'
            );
          }
        }
      }), 'Изменение сервисов')
    },

    async updateSelectorFilters()
    {
      this.countries = await this.sendRequest('/countries/getList');
      this.services = await this.sendRequest('/services/getList');
      this.providers = await this.sendRequest('/providers/getList');
    },
    clearObjects(filters)
    {
      let data = {};
      for (let filter in filters) {
        if (Array.isArray(filters[filter]) && filters[filter].length) {
          data[filter] = filters[filter];
        } else if (filters[filter] !== null) {
          data[filter] = filters[filter];
        }
      }
      return data;
    },

    getIndexByProp(list, propKey, propValue)
    {
      for (let key in list) {
        if (list[key][propKey] == propValue) {
          return key;
        }
      }

      return null;
    },

    toggleShowModalAdd() {
      this.showModalAdd = !this.showModalAdd;
    },
    wrapperForAllSelected: function (data) {
      return [
        {
          group: 'Все',
          list: data
        },
      ];
    },
    sortNumber(a, b)
    {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1
      }

      return 0;
    }
  },

  computed: {
    listToShow: function () {
      let data = [];
      for (let elem of this.filteredList) {
        data.push({
          id: elem['id'],
          provider: elem['provider_service_variation']['provider']['title'] + ' (' + elem['provider_service_variation']['provider']['id'] + ')',
          country: elem['provider_service_variation']['service_variation']['country']['title'] + ' (' + elem['provider_service_variation']['service_variation']['country']['id'] + ')',
          country_img_path: elem['provider_service_variation']['service_variation']['country']['img_path'],
          service: elem['provider_service_variation']['service_variation']['service']['title'] + ' (' + elem['provider_service_variation']['service_variation']['service']['id'] + ')',
          service_img_path: elem['provider_service_variation']['service_variation']['service']['img_path'],
          streams_count: elem['streams_count'],
          sleep_time: elem['sleep_time'],
          caught_phones_count: elem['caught_phones_count'],
          count_canceled: elem['count_canceled'],
          is_active: elem['is_active'],
        });
      }
      return data;
    },

    providerLabels: function () {
      let data = [];
      for (let elem of this.providers) {
        data.push({
          id: elem['id'],
          title: elem['title'] + ' (' + elem['id'] + ')',
        });
      }
      return data;
    },

    serviceLabels: function () {
      let data = [];
      for (let elem of this.services) {
        data.push({
          id: elem['id'],
          title: elem['title'] + ' (' + elem['id'] + ')',
        });
      }
      return data;
    },

    countryLabels: function () {
      let data = [];
      for (let elem of this.countries) {
        data.push({
          id: elem['id'],
          title: elem['title'] + ' (' + elem['id'] + ')',
        });
      }
      return data;
    },
  },

  watch: {
    selectedCountriesToAdd: async function (val) {
      this.paramsToAdd['countriesIds'] = val.map(function (elem) {
        return elem['id'];
      });
    },

    selectedServicesToAdd: async function (val) {
      this.paramsToAdd['servicesIds'] = val.map(function (elem) {
        return elem['id'];
      });
    },

    selectedProvidersToAdd: async function (val) {
      this.paramsToAdd['providersIds'] = val.map(function (elem) {
        return elem['id'];
      });
    },
  }
}
</script>

<style scoped>

</style>
