<template>
  <!-- Pagination -->
  <nav class="pagination fx fx-center">
    <div class="pagination__list fx fx-center">
      <div class="fx position-relative">
        <div v-if="totalPages > 1 && currentPage !== 1" class="pagination__item pagination__item--btn">
          <button class="pagination__link" v-on:click="customPageChange(currentPage - 1)">
            <svg viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M0.909043 12C0.909043 11.7211 1.01568 11.4418 1.22868 11.2288L12.1378 0.319704C12.564 -0.106568 13.2543 -0.106568 13.6803 0.319704C14.1063 0.745976 14.1066 1.43625 13.6803 1.86225L3.54249 12L13.6803 22.1379C14.1066 22.5641 14.1066 23.2544 13.6803 23.6804C13.254 24.1064 12.5638 24.1067 12.1378 23.6804L1.22868 12.7713C1.01568 12.5583 0.909043 12.279 0.909043 12Z"/>
            </svg>
          </button>
        </div>
        <template v-for="page in pages">
          <div v-if="
                    totalPages > 1
                    && (
                      ((page + 2) >= currentPage && page <= currentPage)
                      || ((page - 2) <= currentPage && page > currentPage)
                      || ((currentPage - 2) <= 0 && page <= 5)
                      || ((currentPage + 3) >= totalPages && page >= totalPages - 6)
                    )
            " class="pagination__item"
          >
            <button :class="'pagination__link' + (page === currentPage ? ' pagination__link--active' : '')" v-on:click="customPageChange(page)">{{ page }}</button>
          </div>
        </template>
        <div v-if="totalPages - 4 >= currentPage" class="pagination__item">
          <button class="pagination__link" v-on:click="customPageChange(currentPage + 3 + ((currentPage - 3 < 0) ? -(currentPage - 3) : 0))">...</button>
        </div>
        <div v-if="totalPages - 4 >= currentPage" class="pagination__item">
          <button class="pagination__link" v-on:click="customPageChange(totalPages)">{{ totalPages }}</button>
        </div>
        <div v-if="totalPages > 1 && currentPage !== totalPages" class="pagination__item pagination__item--btn">
          <button class="pagination__link" v-on:click="customPageChange(currentPage + 1)">
            <svg viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M13.091 12C13.091 12.2789 12.9843 12.5582 12.7713 12.7712L1.86225 23.6803C1.43597 24.1066 0.745703 24.1066 0.319704 23.6803C-0.106295 23.254 -0.106568 22.5638 0.319704 22.1378L10.4575 12L0.319704 1.86215C-0.106568 1.43587 -0.106568 0.745602 0.319704 0.319603C0.745976 -0.106398 1.43625 -0.10667 1.86225 0.319603L12.7713 11.2287C12.9843 11.4417 13.091 11.721 13.091 12Z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "customPagination",
  props: {
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    pageChanged: {
      type: Function,
    },
    perPageChanged: {
      type: Function,
    },
  },
  async mounted() {
    this.currentPage = 1;
    this.customPageChange(this.currentPage);
  },

  data: function () {
    return {
      currentPage: 1,
    }
  },
  methods: {
    customPageChange(customCurrentPage) {
      this.pageChanged({currentPage: customCurrentPage});
      this.currentPage = customCurrentPage;
    },
    customPerPageChange(customPerPage) {
      this.perPageChanged({currentPerPage: customPerPage});
    }
  },
  computed: {
    totalPages: function () {
      return Math.ceil(this.total/this.perPage);
    },

    pages: function () {
      let pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }
  }
}
</script>

<style scoped>

</style>