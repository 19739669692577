var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('wrapper-filters',{attrs:{"title":"Запросы провайдерам","filtersUrl":"/providerRequests/getList","filtersUrlMethod":"get","startWithRequest":false,"setEnabledFilters":{
        countries: {
          enable: true,
          multiple: true,
          getDataLink: '/countries/getList',
          placeholder: 'Страны',
          viewLabel: 'titleWithId',
          column: 1,
        },
        services: {
          enable: true,
          multiple: true,
          getDataLink: '/services/getList',
          placeholder: 'Сервисы',
          viewLabel: 'titleWithId',
          column: 2
        },
        date: {
          enable: true,
          column: 2,
        },
        providers: {
          enable: true,
          multiple: true,
          getDataLink: '/providers/getList',
          placeholder: 'Провайдеры',
          viewLabel: 'titleWithId',
          column: 3
        },
        users: {
          enable: true,
          multiple: true,
          getDataLink: '/users/getUsers',
          placeholder: 'Клиенты',
          specifyKeyForLabel: 'name',
          viewLabel: 'titleWithId',
          column: 3
        },
      }},on:{"set-filtered-list":_vm.setFilteredList}}),_vm._v(" "),_c('vue-good-table',{attrs:{"title":"Service variations","columns":_vm.columns,"rows":_vm.listToShow,"lineNumbers":false,"sort-options":{
          enabled: true,
        },"style-class":'vgt-table admin-table',"globalSearch":true,"pagination-options":{
          enabled: true,
          perPage: _vm.perPage
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_c('div',{staticClass:"admin-table-element",staticStyle:{"line-height":"1.3","font-size":"13px"}},[(props.column.field === 'service')?[_c('img',{attrs:{"src":props.row['service_img_path'],"alt":"","width":"20px"}}),_vm._v("\n          "+_vm._s(props.formattedRow[props.column.field])+" | \n          "),_c('img',{attrs:{"src":props.row['country_img_path'],"alt":"","width":"20px"}}),_vm._v("\n          "+_vm._s(props.row['country'])+" | "+_vm._s(props.row['provider'])+"\n        ")]:(props.column.field === 'country')?[_c('img',{attrs:{"src":props.row['country_img_path'],"alt":"","width":"20px"}}),_vm._v("\n          "+_vm._s(props.formattedRow[props.column.field])+"\n        ")]:[_vm._v("\n          "+_vm._s(props.formattedRow[props.column.field])+"\n        ")]],2)]}},{key:"pagination-bottom",fn:function(props){return [_c('custom-pagination',{attrs:{"total":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged,"perPage":_vm.perPage}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }