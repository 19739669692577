var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('wrapper-filters',{attrs:{"title":"Свободные активации","filtersUrl":"/caughtPhoneActivations/getList","filtersUrlMethod":"get","startWithRequest":true,"setEnabledFilters":{
        date: {
          enable: true,
          column: 1,
        },
        dateSelector: {
          enable: true,
          placeholder: 'Период',
          noGroupSelect: true,
          closeOnSelect: true,
          selectedKey: 'dateSelector',
          data: [
              {
                id: 1,
                title: 'За последние 20 минут'
              },
              {
                id: 2,
                title: 'За последний час'
              },
              {
                id: 3,
                title: 'За последний день'
              },
              {
                id: 4,
                title: 'За последние 7 дней'
              },
              {
                id: 5,
                title: 'За последние 30 дней'
              },
          ],
          defaultValue: {
            id: 2,
            title: 'За последний час'
          },
          column: 1,
        },
        countries: {
          enable: true,
          multiple: true,
          getDataLink: '/countries/getList',
          placeholder: 'Страны',
          viewLabel: 'titleWithId',
          column: 2,
        },
        services: {
          enable: true,
          multiple: true,
          getDataLink: '/services/getList',
          placeholder: 'Сервисы',
          viewLabel: 'titleWithId',
          column: 2
        },
        statuses: {
          enable: true,
          multiple: true,
          selectedKey: 'statuses',
          getDataLink: '/phoneActivationStatuses/getList',
          placeholder: 'Статусы',
          specifyKeyForLabel: 'description',
          column: 3
        },
        providers: {
          enable: true,
          multiple: true,
          getDataLink: '/providers/getList',
          placeholder: 'Провайдеры',
          viewLabel: 'titleWithId',
          column: 3
        },
      }},on:{"set-filtered-list":_vm.setFilteredList}}),_vm._v(" "),_c('vue-good-table',{attrs:{"title":"Service variations","columns":_vm.columns,"rows":_vm.listToShow,"lineNumbers":false,"sort-options":{
            enabled: true,
          },"style-class":'vgt-table admin-table',"globalSearch":true,"pagination-options":{
            enabled: true,
            perPage: _vm.perPage
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_c('div',{staticClass:"admin-table-element",staticStyle:{"font-size":"16px"}},[(props.column.field === 'service')?[_c('img',{attrs:{"src":props.row['service_img_path'],"alt":"","width":"20px"}}),_vm._v("\n          "+_vm._s(props.formattedRow[props.column.field])+"\n        ")]:(props.column.field === 'country')?[_c('img',{attrs:{"src":props.row['country_img_path'],"alt":"","width":"20px"}}),_vm._v("\n          "+_vm._s(props.formattedRow[props.column.field])+"\n        ")]:[_vm._v("\n          "+_vm._s(props.formattedRow[props.column.field])+"\n        ")]],2)]}},{key:"pagination-bottom",fn:function(props){return [_c('custom-pagination',{attrs:{"total":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged,"perPage":_vm.perPage}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }