<template>
  <div>
    <wrapper-filters
        title="Запросы провайдерам"
        filtersUrl="/providerRequests/getList"
        filtersUrlMethod="get"
        :startWithRequest="false"
        :setEnabledFilters="{
          countries: {
            enable: true,
            multiple: true,
            getDataLink: '/countries/getList',
            placeholder: 'Страны',
            viewLabel: 'titleWithId',
            column: 1,
          },
          services: {
            enable: true,
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Сервисы',
            viewLabel: 'titleWithId',
            column: 2
          },
          date: {
            enable: true,
            column: 2,
          },
          providers: {
            enable: true,
            multiple: true,
            getDataLink: '/providers/getList',
            placeholder: 'Провайдеры',
            viewLabel: 'titleWithId',
            column: 3
          },
          users: {
            enable: true,
            multiple: true,
            getDataLink: '/users/getUsers',
            placeholder: 'Клиенты',
            specifyKeyForLabel: 'name',
            viewLabel: 'titleWithId',
            column: 3
          },
        }"
        @set-filtered-list="setFilteredList"
    >
    </wrapper-filters>
    <!-- Table -->
    <vue-good-table
      title="Service variations"
      :columns="columns"
      :rows="listToShow"
      :lineNumbers="false"
      :sort-options="{
            enabled: true,
          }"
      :style-class="'vgt-table admin-table'"
      :globalSearch="true"
      :pagination-options="{
            enabled: true,
            perPage: perPage
          }"
    >
      <template v-slot:table-row="props">
        <div class="admin-table-element" style="line-height: 1.3; font-size: 13px">
          <template v-if="props.column.field === 'service'">
            <img :src="props.row['service_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}&nbsp;|&nbsp;
            <img :src="props.row['country_img_path']" alt="" width="20px">
            {{ props.row['country'] }}&nbsp;|&nbsp;{{ props.row['provider'] }}
          </template>
          <template v-else-if="props.column.field === 'country'">
            <img :src="props.row['country_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
      <template v-slot:pagination-bottom="props">
        <custom-pagination
          :total="props.total"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
          :perPage="perPage"
        >
        </custom-pagination>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import CustomPagination from "../../../components/customPagination";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../../../assets/css/multiselectCustomization.css';
import BaseAdminMixin from "../mixins/BaseAdminMixin";
import Modal from '../../../components/modal';
import WrapperFilters from "../../../components/wrapperFilters"

export default {
  name: "providerRequestsTable",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect,
    Modal,
    WrapperFilters
  },
  data: function () {
    return {
      filteredList: [],
      columns: [
        {
          label: 'Сервис',
          field: 'service',
          width: '18%',
        },
        {
          label: 'Клиент',
          field: 'user',
          width: '10%',
        },
        {
          label: 'Активация',
          field: 'phone_activation_id',
          width: '7%',
          sortable: false,
        },
        {
          label: 'Запрос',
          field: 'request',
          width: '30%',
        },
        {
          label: 'Ответ',
          field: 'response',
          width: '15%',
        },
        {
          label: 'Ошибка',
          field: 'error_message',
          width: '10%',
        },
        {
          label: 'Дата',
          field: 'created_at',
          width: '10%',
        },
      ],
      filters: {
        dateFrom: null,
        dateTo: null,
      },
      showFilters: true,
      perPage: 25,
      timeout: null,
    };
  },
  methods: {
    setFilteredList(response) {
      this.filteredList = response;
    },

    getIndexByProp(list, propKey, propValue) {
      for (let key in list) {
        if (list[key][propKey] == propValue) {
          return key;
        }
      }

      return null;
    },

    toggleShowModalAdd() {
      this.showModalAdd = !this.showModalAdd;
    },
  },
  computed: {
    listToShow: function () {
      let data = [];
      for (let elem of this.filteredList) {
        data.push({
          id: elem['id'],
          phone_activation_id: elem['phone_activation_id'],
          service: elem['provider_service_variation'] ? elem['provider_service_variation']['service_variation']['service']['title'] : '-',
          country: elem['provider_service_variation'] ? elem['provider_service_variation']['service_variation']['country']['title'] : '-',
          service_img_path: elem['provider_service_variation'] ? elem['provider_service_variation']['service_variation']['service']['img_path'] : '',
          country_img_path: elem['provider_service_variation'] ? elem['provider_service_variation']['service_variation']['country']['img_path'] : '',
          provider: elem['provider_service_variation'] ? elem['provider_service_variation']['provider']['id'] : '-',
          user: elem['user'] ? elem['user']['name'] : '-',
          request: elem['request'],
          response: elem['response'],
          error_message: elem['error_message'],
          created_at: elem['created_at'],
        });
      }

      return data;
    },
  },
}
</script>

<style scoped>

</style>
