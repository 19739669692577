<script>

export default {
  name: "GraphDynamicMixin",
  mounted() {
    this.DATA_SET_COLORS = {
      default: {
        pointBackgroundColor: 'white',
        borderWidth: 3,
      },
      0: {
        backgroundColor: "rgb(34,152,167)",
        pointBorderColor: '#249EBF',
        borderColor: "rgb(34,152,167)",
      },
      1: {
        backgroundColor: "rgb(255,99,128)",
        borderColor: "rgb(255,99,128)",
        pointBorderColor: 'rgba(255,86,130,0.91)',
      },
      2: {
        backgroundColor: "rgb(243,103,18)",
        borderColor: "rgb(243,103,18)",
        pointBorderColor: 'rgba(243,103,18,0.71)',
      },
      3: {
        backgroundColor: "rgb(170,55,229)",
        borderColor: "rgb(170,55,229)",
        pointBorderColor: 'rgb(170,55,229)',
      },
      4: {
        backgroundColor: "rgb(37,128,67)",
        borderColor: "rgb(37,128,67)",
        pointBorderColor: 'rgb(37,128,67)',
      },
      5: {
        backgroundColor: "rgb(21,66,218)",
        borderColor: "rgb(21,66,218)",
        pointBorderColor: 'rgb(21,66,218)',
      },
      6: {
        backgroundColor: "rgb(129,234,6)",
        borderColor: "rgb(129,234,6)",
        pointBorderColor: 'rgb(129,234,6)',
      },
      7: {
        backgroundColor: "rgb(232,5,5)",
        borderColor: "rgb(232,5,5)",
        pointBorderColor: 'rgb(232,5,5)',
      },
      8: {
        backgroundColor: "rgb(232,202,6)",
        borderColor: "rgb(232,202,6)",
        pointBorderColor: 'rgb(232,202,6)',
      },
      9: {
        backgroundColor: "rgba(46,255,120,0.71)",
        borderColor: "rgba(46,255,120,0.71)",
        pointBorderColor: 'rgba(46,255,120,0.71)',
      },
      10: {
        backgroundColor: "rgb(0,0,0)",
        borderColor: "rgb(0,0,0)",
        pointBorderColor: 'rgb(0,0,0)',
      },
      11: {
        backgroundColor: "rgb(62,3,110)",
        borderColor: "rgb(62,3,110)",
        pointBorderColor: 'rgb(62,3,110)',
      },


    }
  },
  data: function () {
    return {
      loaded: false,
      filteredList: [],
      filters: {},
      showFilters: true,
      chartData: {
        labels: [],
        datasets: []
      },
    };
  },
  methods: {
    setFilteredList(response) {
      this.clearChartData();
      if (this.$data.type && this.$data.type === 'pie'){
        return this.setFilteredListPie(response);
      }
      let i = 0;
      for (let dataLabel in response) {
        let obj = {
          label: dataLabel,
          data: Object.values(response[dataLabel])
        };
        obj = Object.assign(obj, this.DATA_SET_COLORS['default']);
        if (this.DATA_SET_COLORS[i]) {
          obj = Object.assign(obj, this.DATA_SET_COLORS[i]);
        } else {
          obj = Object.assign(obj, this.DATA_SET_COLORS[0]);
          i = 0;
        }
        if (this.chartData.labels.length < 1 || this.chartData.labels < Object.keys(response[dataLabel])) {
          this.chartData.labels = Object.keys(response[dataLabel]);
        }
        this.chartData.datasets.push(obj);
        i++;
      }
      this.loaded = true;
    },
    clearChartData() {
      this.chartData = {
        labels: [],
        datasets: []
      };
    },
    setFilteredListPie(response) {
      let i = 0;
      this.chartData.datasets = [{
        backgroundColor: [],
        data: []
      }];
      if (response['label']) {
        this.title = response['label'];
      }

      for (let dataLabel in response['data']) {
        this.chartData.labels.push(dataLabel);
        if (!this.DATA_SET_COLORS[i]){
          i = 0;
        }
        let color = this.DATA_SET_COLORS[0].backgroundColor;
        if (this.DATA_SET_COLORS[i] && this.DATA_SET_COLORS[i].backgroundColor) {
          color = this.DATA_SET_COLORS[i].backgroundColor;
        }
        this.chartData.datasets[0].backgroundColor.push(color);
        this.chartData.datasets[0].data.push(response['data'][dataLabel]);
        i++;
      }
      this.loaded = true;
    }
  },

}
</script>
