<template>
  <div>
    <wrapper-filters
        title="Общая статистика по клиентам"
        filtersUrl="/statistic/users/usersOnlyStatistic/getList"
        filtersUrlMethod="get"
        :setEnabledFilters="{
          date: {
            enable: true,
            column: 1,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
                {
                  id: 1,
                  title: 'За последние 20 минут'
                },
                {
                  id: 2,
                  title: 'За последний час'
                },
                {
                  id: 3,
                  title: 'За последний день'
                },
                {
                  id: 4,
                  title: 'За последние 7 дней'
                },
                {
                  id: 5,
                  title: 'За последние 30 дней'
                },
            ],
            column: 1,
          },
        }"
        @set-filtered-list="setFilteredList"
        saveCsvUrl = "/statistic/users/usersOnlyStatistic/downloadCsvUserStat"
        :enableSaveCsv="true"
    >
    </wrapper-filters>
    <!-- Table -->
    <vue-good-table
        title="Service variations"
        :columns="columns"
        :rows="listToShow"
        :lineNumbers="false"
        :sort-options="{
            enabled: false,
            // initialSortBy: {
            //   field: 'provider',
            //   type: 'asc'
            // },
        }"
        :style-class="'vgt-table admin-table'"
        :globalSearch="true"
        :pagination-options="{
            enabled: true,
            perPage: perPage
        }"
    >
      <template v-slot:table-row="props">
        <div class="admin-table-element">
          <template>
            <span v-html="props.formattedRow[props.column.field]"></span>
          </template>
        </div>
      </template>
      <template v-slot:pagination-bottom="props">
        <custom-pagination
            :total="props.total"
            :pageChanged="props.pageChanged"
            :perPageChanged="props.perPageChanged"
            :perPage="perPage"
        >
        </custom-pagination>
      </template>
      <div v-show="sumElem.show" slot="table-actions-bottom">
        <div class="wrapper">
          <h3>Суммарно</h3>
          <div class="sum-data">
            <div>Номеров отдано: {{ sumElem.count_received }}</div>
            <div>Смс отдано: {{ sumElem.count_success }}</div>
            <div>Доставка смс: {{ sumElem.delivery }}</div>
            <div>Выручка: {{ sumElem.income }}</div>
            <div>Прибыль: {{ sumElem.profit }}</div>
            <div>Расход: {{ sumElem.expense }}</div>
            <div>Margin: {{ sumElem.margin }}</div>
          </div>
        </div>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import CustomPagination from "../../../components/customPagination";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../../../assets/css/multiselectCustomization.css';
import BaseAdminMixin from "../mixins/BaseAdminMixin";
import WrapperFilters from "../../../components/wrapperFilters"

export default {
  name: "usersOnlyStatistic",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect,
    WrapperFilters,
  },
  data: function () {
    return {
      filteredList: [],
      columns: [
        {
          label: 'Клиент',
          field: 'user',
          width: '20%',
          sortable: true
        },
        {
          label: 'Номеров отдано',
          field: 'count_received',
          width: '7%',
          sortable: true,
          sortFn: this.sortNumber,
        },
        {
          label: 'Смс отдано',
          field: 'count_success',
          width: '7%',
          sortable: true,
          sortFn: this.sortNumber,
        },
        {
          label: 'Доставка смс',
          field: 'delivery',
          width: '8%',
          sortable: true,
          sortFn: this.sortNumbersWithPercent,
        },
        {
          label: 'Выручка',
          field: 'income',
          width: '10%',
          sortable: true,
          sortFn: this.sortNumber,
        },
        {
          label: 'Прибыль',
          field: 'profit',
          width: '10%',
          sortable: true,
          sortFn: this.sortNumber,
        },
        {
          label: 'Маржа',
          field: 'margin',
          width: '6%',
          sortable: true,
          sortFn: this.sortNumber,
        },
        {
          label: 'Расход',
          field: 'expense',
          width: '6%',
          sortable: true,
          sortFn: this.sortNumber,
        },
      ],
      filters: {
        dateFrom: null,
        dateTo: null,
      },
      showFilters: true,
      perPage: 1000,
      timeout: null,
      sumElem: {
        count_received: 0,
        count_success: 0,
        delivery: 0,
        income: 0,
        profit: 0,
        margin: 0,
        expense: 0,
        show: false
      },
    };
  },
  methods: {
    setFilteredList(response) {
      this.filteredList = response;
    },
    getIndexByProp(list, propKey, propValue) {
      for (let key in list) {
        if (list[key][propKey] == propValue) {
          return key;
        }
      }

      return null;
    },
    sortNumbersWithPercent(a, b) {
      return this.sortNumber(a.replace('%', ''), b.replace('%', ''))
    },
    clearSumElement() {
      for (let elem in this.sumElem) {
        if (elem !== 'show') {
          this.sumElem[elem] = 0;
        } else {
          this.sumElem.show = false
        }
      }
    },
  },

  computed: {
    listToShow: function () {
      let data = [];
      this.clearSumElement();
      for (let elem of this.filteredList) {
        this.sumElem.count_received += elem['count_received'];
        this.sumElem.count_success += elem['count_success'];
        this.sumElem.income += elem['income'];
        this.sumElem.profit += elem['profit'];
        this.sumElem.expense += elem['expense'];
        data.push({
          user: elem['user']['name'] + '(' + elem['user']['id'] + ')',
          count_received: Number(elem['count_received']).toFixed(0),
          count_success: Number(elem['count_success']).toFixed(0),
          delivery: Number(elem['delivery']).toFixed(2) + '%',
          income: Number(elem['income']).toFixed(2),
          profit: Number(elem['profit']).toFixed(2),
          expense: Number(elem['expense']).toFixed(2),
          margin: Number(elem['margin']).toFixed(2) + '%',
        });
      }
      if (this.filteredList.length) {
        this.sumElem.show = true;
        this.sumElem.delivery = Number(this.sumElem.count_success * 100 / this.sumElem.count_received).toFixed(2) + '%';
        this.sumElem.income = Number(this.sumElem.income).toFixed(2);
        this.sumElem.profit = Number(this.sumElem.profit).toFixed(2);
        this.sumElem.expense = Number(this.sumElem.expense).toFixed(2);
        if (Number(this.sumElem.income)) {
          this.sumElem.margin = Number(this.sumElem.profit * 100 / this.sumElem.income).toFixed(2) + '%';
        } else {
          this.sumElem.margin = 0 + '%';
        }
      }
      return data;
    },
  },
}
</script>

<style scoped>

</style>

<style scoped>
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
}

.sum-data {
  margin-left: calc(100% - 90%);
  display: flex;
  justify-content: space-between;
}

.sum-data div {
  margin: 0 15px;
}
</style>
