<template>
  <div>
    <div class="wrapperCanvas">
      <div v-if="loaded" class="d-flex justify-content-between flex-wrap">
        <div v-for="(item, index) in this.preparedData" style="width: 400px">
          <LineChart
              :width="300"
              :height="400"
              :chart-id="String(index)"
              :chart-data="item"
          ></LineChart>
        </div>
      </div>
      <div class="d-flex justify-content-between flex-wrap">
        <div v-for="(item, index) in this.barData" style="width: 400px" class="mt-5">
          <BarChart
              :width="300"
              :height="400"
              :chart-id="String(index)"
              :chart-data="item"
          ></BarChart>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import LineChart from "../../../components/lineChart.vue";
import BarChart from "../../../components/barChart.vue";
import GraphDynamic from "../../../components/mixins/GraphDynamicMixin.vue";

export default {
  name: "dynamicStatistic",
  mixins: [
    GraphDynamic,
  ],
  components: {
    LineChart,
    BarChart
  },
  props: {
    data: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      loaded: false,
      preparedData: [],
      barData: [],
      i: 1
    }
  },
  mounted() {
    for (let dataLabel in this.data) {
      if (dataLabel === 'bar') {
        for (let barEl in this.data[dataLabel]) {
          let obj = {
            label: barEl,
            data: Object.values(this.data[dataLabel][barEl]),
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1
          };
          for (let i = 0; i < 4; i++) {
            obj.backgroundColor.push(this.DATA_SET_COLORS[i].backgroundColor);
            obj.borderColor.push(this.DATA_SET_COLORS[i].borderColor);
          }
          let res = {
            datasets: [obj],
            labels: Object.keys(this.data[dataLabel][barEl])
          };
          this.barData.push(res);
        }
      } else {
        let obj = {
          label: dataLabel,
          data: Object.values(this.data[dataLabel])
        };
        obj = Object.assign(obj, this.DATA_SET_COLORS['default']);
        let color = this.DATA_SET_COLORS[this.i];
        if (this.i > 11) {
          this.i = 0;
          color = this.DATA_SET_COLORS[this.i];
        }
        obj = Object.assign(obj, color);
        let res = {
          datasets: [obj],
          labels: Object.keys(this.data[dataLabel])
        };
        this.preparedData.push(res);
        this.i++;
      }
    }

    this.loaded = true;
  }
}
</script>

<style scoped>
.wrapperCanvas {
  position: relative;
  margin: auto;
  height: 50vh;
  width: 80vw;
}
</style>
