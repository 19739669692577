var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('wrapper-filters',{attrs:{"title":"Phone history","filtersUrl":"phones/getList","filtersUrlMethod":"get","setEnabledFilters":{
        date: {
          enable: true,
          column: 1,
        },
        dateSelector: {
          enable: true,
          placeholder: 'Period',
          noGroupSelect: true,
          closeOnSelect: true,
          selectedKey: 'dateSelector',
          data: [
              {
                id: 1,
                title: 'Last 20 minutes'
              },
              {
                id: 2,
                title: 'Last hour'
              },
              {
                id: 3,
                title: 'Last day'
              },
          ],
          defaultValue: {
            id: 1,
            title: 'Last 20 minutes'
          },
          column: 1,
        },
        countries: {
          enable: true,
          multiple: true,
          getDataLink: 'phonesHistory/getCountries',
          placeholder: 'Countries',
          viewLabel: 'titleWithId',
          column: 2,
        },
        services: {
          enable: true,
          multiple: true,
          getDataLink: 'phonesHistory/getServices',
          placeholder: 'Service',
          viewLabel: 'titleWithId',
          column: 2
        },
      },"enableSaveCsv":true,"saveCsvUrl":"phones/downloadCsv"},on:{"set-filtered-list":_vm.setFilteredList}}),_vm._v(" "),_c('div',[_c('h3',[_vm._v("Total numbers: "+_vm._s(_vm.totalCountPhones))]),_vm._v(" "),_c('h3',[_vm._v("Total price: "+_vm._s(_vm.totalSumByFilters))])]),_vm._v(" "),_c('vue-good-table',{attrs:{"title":"My phone numbers","columns":_vm.columns,"rows":_vm.listToShow,"lineNumbers":true,"sort-options":{
          enabled: true,
        },"style-class":'vgt-table admin-table',"globalSearch":true,"pagination-options":{
            enabled: true,
            perPage: _vm.perPage,
            jumpFirstOrLast: true,
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_c('div',{staticStyle:{"font-size":"16px"}},[(props.column.field === 'phone_number')?[_vm._v("\n          "+_vm._s(props.formattedRow[props.column.field])+"\n        ")]:(props.column.field === 'country_name')?[_vm._v("\n          "+_vm._s(props.formattedRow[props.column.field])+"\n        ")]:(props.column.field === 'country_code')?[_c('img',{staticClass:"vue-td-img",attrs:{"src":props.row['country_img_path'],"alt":""}}),_vm._v(_vm._s(props.row['iso'])+"\n          "+_vm._s(props.formattedRow[props.column.field])+"\n        ")]:(props.column.field === 'service')?[_c('img',{staticClass:"vue-td-img",attrs:{"src":props.row['service_img_path'],"alt":"","width":"50px"}}),_vm._v(_vm._s(props.row['iso'])+"\n          "+_vm._s(props.formattedRow[props.column.field])+"\n        ")]:(props.column.field === 'price')?[_vm._v("\n          "+_vm._s(props.formattedRow[props.column.field])+"\n        ")]:(props.column.field === 'sms_code')?[_vm._v("\n          "+_vm._s(props.formattedRow[props.column.field])+"\n        ")]:(props.column.field === 'created_at')?[_vm._v("\n          "+_vm._s(props.formattedRow[props.column.field])+"\n        ")]:_vm._e()],2)]}},{key:"pagination-bottom",fn:function(props){return [_c('custom-pagination',{attrs:{"total":_vm.totalCountPhones,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged,"perPage":_vm.perPage}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }