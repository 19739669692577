var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('wrapper-filters',{attrs:{"title":"Подробная статистика по клиентам","filtersUrl":"/statistic/users/full/getList","filtersUrlMethod":"get","setEnabledFilters":{
        date: {
          enable: true,
          column: 1,
        },
        dateSelector: {
          enable: true,
          placeholder: 'Период',
          noGroupSelect: true,
          closeOnSelect: true,
          selectedKey: 'dateSelector',
          data: [
              {
                id: 1,
                title: 'За последние 20 минут'
              },
              {
                id: 2,
                title: 'За последний час'
              },
              {
                id: 3,
                title: 'За последний день'
              },
              {
                id: 4,
                title: 'За последние 7 дней'
              },
              {
                id: 5,
                title: 'За последние 30 дней'
              },
          ],
          column: 1,
        },
        services: {
          enable: true,
          multiple: true,
          getDataLink: '/services/getList',
          placeholder: 'Сервисы',
          viewLabel: 'titleWithId',
          column: 2
        },
        countries: {
          enable: true,
          multiple: true,
          getDataLink: '/countries/getList',
          placeholder: 'Страны',
          viewLabel: 'titleWithId',
          column: 2,
        },
        users: {
          enable: true,
          multiple: true,
          getDataLink: '/users/getUsers',
          placeholder: 'Клиенты',
          specifyKeyForLabel: 'name',
          viewLabel: 'titleWithId',
          column: 3
        },
        providers: {
          enable: true,
          multiple: true,
          getDataLink: '/providers/getList',
          placeholder: 'Провайдеры',
          viewLabel: 'titleWithId',
          column: 3
        },
      },"saveCsvUrl":"/statistic/users/full/downloadCsvClients","enableSaveCsv":true},on:{"set-filtered-list":_vm.setFilteredList}}),_vm._v(" "),_c('vue-good-table',{attrs:{"title":"Service variations","columns":_vm.columns,"rows":_vm.listToShow,"lineNumbers":false,"sort-options":{
          enabled: false,
          // initialSortBy: {
          //   field: 'provider',
          //   type: 'asc'
          // },
      },"style-class":'vgt-table admin-table',"globalSearch":true,"pagination-options":{
          enabled: true,
          perPage: _vm.perPage
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_c('div',{staticClass:"admin-table-element"},[(props.column.field === 'service')?[_c('img',{attrs:{"src":props.row['service_img_path'],"alt":"","width":"20px"}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(props.formattedRow[props.column.field])}})]:(props.column.field === 'country')?[_c('img',{attrs:{"src":props.row['country_img_path'],"alt":"","width":"20px"}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(props.formattedRow[props.column.field])}})]:[_c('span',{domProps:{"innerHTML":_vm._s(props.formattedRow[props.column.field])}})]],2)]}},{key:"pagination-bottom",fn:function(props){return [_c('custom-pagination',{attrs:{"total":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged,"perPage":_vm.perPage}})]}}])},[_vm._v(" "),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.sumElem.show),expression:"sumElem.show"}],attrs:{"slot":"table-actions-bottom"},slot:"table-actions-bottom"},[_c('div',{staticClass:"wrapper"},[_c('h3',[_vm._v("Суммарно")]),_vm._v(" "),_c('div',{staticClass:"sum-data"},[_c('div',[_vm._v("Номеров отдано: "+_vm._s(_vm.sumElem.count_received))]),_vm._v(" "),_c('div',[_vm._v("Смс отдано: "+_vm._s(_vm.sumElem.count_success))]),_vm._v(" "),_c('div',[_vm._v("Доставка смс: "+_vm._s(_vm.sumElem.delivery))]),_vm._v(" "),_c('div',[_vm._v("Выручка: "+_vm._s(_vm.sumElem.income))]),_vm._v(" "),_c('div',[_vm._v("Прибыль: "+_vm._s(_vm.sumElem.profit))]),_vm._v(" "),_c('div',[_vm._v("Margin: "+_vm._s(_vm.sumElem.margin))])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }