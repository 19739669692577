<template>
  <div>
    <base-table
      :render-list-to-show="this.listToShow"
      :per-page="100"
      :wrapper-filters-data="this.wrapperFiltersData"
      :columns="this.columns"
      :enable-save-csv="true"
      :csv-filename="'utmStat'"
      saveCsvUrl ="/statistic/byUtmSource/downloadCsv"
    >
      <template v-slot:base-table-actions-bottom>
        <div v-show="overallStat.show" slot="table-actions-bottom">
          <div class="wrapper">
            <div class="sum-data">
              <div>Всего уникальных переходов: <span class="red">{{new Intl.NumberFormat().format(overallStat.overallCount)}}</span></div>
            </div>
          </div>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from "../../../components/baseTable.vue";
import CustomPagination from "../../../components/customPagination.vue";
import Multiselect from "vue-multiselect";
import WrapperFilters from "../../../components/wrapperFilters.vue";
export default {
  name: "partnerStatistic",
  components: {
    CustomPagination,
    Multiselect,
    WrapperFilters,
    BaseTable
  },
  data: function () {
    return {
      wrapperFiltersData: {
        title: "Статистика по источникам переходам",
        filtersUrl: "/statistic/byUtmSource/getStat",
        filtersUrlMethod: "get",
        items: {
          date: {
            enable: true,
            column: 1,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
              {
                id: 2,
                title: 'За последний час'
              },
              {
                id: 3,
                title: 'За последний день'
              },
              {
                id: 4,
                title: 'За последние 7 дней'
              },
              {
                id: 5,
                title: 'За последние 30 дней'
              },
            ],
            column: 2,
          },
        },
      },
      columns: [
        {
          label: 'UTMSource',
          field: 'utm_source',
          sortable: false,
          width: '10%'
        },
        {
          label: 'UTMTerm',
          field: 'utm_term',
          sortable: false,
          width: '10%'
        },
        {
          label: 'UTMCampaign',
          field: 'utm_campaign',
          sortable: false,
          width: '10%'
        },
        {
          label: 'Кол-во переходов',
          field: 'count_of_visits',
          type: 'number',
          sortable: true,
          width: '10%'
        },
        {
          label: 'Кол-во пополнений баланса',
          field: 'count_of_payments',
          type: 'number',
          sortable: true,
          width: '20%',
        },
        {
          label: 'Сумма пополнений баланса',
          field: 'sum_of_payments',
          type: 'number',
          sortable: true,
          width: '20%'
        },
        {
          label: '% пополнений к регистрациям',
          field: 'payments_to_registrations',
          sortable: true,
          type: 'number',
          width: '20%',
        },
        {
          label: '% регистраций к пополнениям ',
          field: 'registrations_to_payments',
          sortable: true,
          type: 'number',
          width: '20%'
        },
        {
          label: 'Кол-во регистраций',
          field: 'count_of_registrations',
          sortable: true,
          type: 'number',
          width: '10%'
        },
      ],
      overallStat: {
        show: false,
        overallCount: null
      }
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      if(list.length === 0){
        return data;
      }
      for (let elem in list) {
        let utm_source = list[elem]['utm_source'] === null ? 'Органика' : list[elem]['utm_source'];
        let utm_campaign = list[elem]['utm_campaign'] == 'without_utm_organic' ? 'Органика' : list[elem]['utm_campaign'];
        let utm_term = list[elem]['utm_term'] == 'without_utm_organic' ? 'Органика' : list[elem]['utm_term'];
        data.push({
          utm_source: utm_source,
          utm_campaign: utm_campaign,
          utm_term: utm_term,
          count_of_visits: list[elem]['visits'],
          count_of_payments: list[elem]['count_of_payments'],
          sum_of_payments: list[elem]['sum_of_payments'],
          payments_to_registrations: list[elem]['payments_to_registrations'],
          registrations_to_payments: list[elem]['registrations_to_payments'],
          count_of_registrations: list[elem]['count_of_registrations']
        })
      }
      this.overallStat.show = true;
      this.overallStat.overallCount = list['totals'];
      return data;
    },
  }
}
</script>
