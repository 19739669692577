<template>
  <div>
    <base-table
      :render-list-to-show="this.listToShow"
      :per-page="100"
      :wrapper-filters-data="this.wrapperFiltersData"
      :columns="this.columns"
      :enable-save-csv="true"
    >
      <template v-slot:base-table-row="props">
        <a :href="'/permissions/roles/edit/' + props.row['id']">
          <template v-if="props.column.field !== 'is_popular'">
            {{ props.formattedRow[props.column.field] }}
          </template>
        </a>
        <template v-if="props.column.field == 'delete'">
          <button type="button" class="btn btn-danger" @click="props.deleteById(props.row['id'])">Удалить статью
          </button>
        </template>
        <template v-else-if="props.column.field === 'is_popular'">
          <button v-if="props.formattedRow[props.column.field]" class="btn btn-success w-90"
                  @click="props.changeItemWithLoader(props.row['id'], {is_popular: false})">ДА
          </button>
          <button v-else class="btn btn-danger w-90"
                  @click="props.changeItemWithLoader(props.row['id'], {is_popular: true})">НЕТ
          </button>
        </template>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseAdminMixin from "../mixins/BaseAdminMixin";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/baseTable.vue";

export default {
  name: 'blogList',
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    Multiselect,
    BaseTable
  },
  data: function () {
    return {
      wrapperFiltersData: {
        title: "Список ролей",
        filtersUrl: "/permissions/roles/getList",
        filtersUrlMethod: "get",
        startWithRequest: true,
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          width: '10%',
        },
        {
          label: 'Заголовок',
          field: 'title',
          width: '15%',
        },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          title: elem['name'],
        })
      }

      return data;
    },
  }
}
</script>
