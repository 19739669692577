var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('wrapper-filters',{attrs:{"title":"Динамика активаций","filtersUrl":"/statistic/dynamicPhoneActivation/getList","filtersUrlMethod":"get","setEnabledFilters":{
        dateWithOutTime: {
          enable: true,
          column: 1,
        },
        dateSelector: {
          enable: true,
          placeholder: 'Период',
          noGroupSelect: true,
          closeOnSelect: true,
          selectedKey: 'dateSelector',
          data: [
              {
                id: 4,
                title: 'За 1 неделю'
              },
              {
                id: 6,
                title: 'За 2 недели'
              },
              {
                id: 7,
                title: 'За 3 недели'
              },
              {
                id: 5,
                title: 'За месяц'
              },
          ],
          column: 1,
        },
        countries: {
          enable: true,
          noGroupSelect: true,
          closeOnSelect: true,
          allowEmpty: false,
          getDataLink: '/countries/getList',
          placeholder: 'Страны',
          viewLabel: 'titleWithId',
          selectedKey: 'countryId',
          column: 2,
        },
        services: {
          enable: true,
          multiple: true,
          getDataLink: '/services/getList',
          placeholder: 'Сервисы',
          viewLabel: 'titleWithId',
          column: 2
        },
        users: {
          enable: true,
          multiple: true,
          getDataLink: '/users/getUsers',
          placeholder: 'Клиенты',
          specifyKeyForLabel: 'name',
          viewLabel: 'titleWithId',
          column: 3
        },
        providers: {
          enable: true,
          multiple: true,
          getDataLink: '/providers/getList',
          placeholder: 'Провайдеры',
          viewLabel: 'titleWithId',
          column: 3
        },
      }},on:{"set-filtered-list":_vm.setFilteredList}}),_vm._v(" "),_c('div',{staticClass:"wrapperCanvas"},[(_vm.loaded)?_c('PieChartGenerator',{attrs:{"chart-data":_vm.chartData,"title":_vm.title}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }