var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('wrapper-filters',{attrs:{"title":"Динамика пополнений","filtersUrl":"/statistic/dynamicTopUp/getList","filtersUrlMethod":"get","setEnabledFilters":{
        date: {
          enable: true,
          column: 1,
        },
        dateSelector: {
          enable: true,
          placeholder: 'Период',
          noGroupSelect: true,
          closeOnSelect: true,
          selectedKey: 'dateSelector',
          data: [
              {
                id: 4,
                title: 'За 1 неделю'
              },
              {
                id: 6,
                title: 'За 2 недели'
              },
              {
                id: 7,
                title: 'За 3 недели'
              },
              {
                id: 5,
                title: 'За месяц'
              },
          ],
          column: 1,
        },
        graphType: {
          enable: true,
          placeholder: 'Группировка по: ',
          noGroupSelect: true,
          closeOnSelect: true,
          selectedKey: 'groupBy',
          data: [
              {
                id: 1,
                title: 'Сервис пополнения'
              },
          ],
          column: 2

        },
        topUpServices: {
          enable: true,
          multiple: true,
          getDataLink: '/statistic/dynamicTopUp/getListServices',
          placeholder: 'Сервисы пополнения',
          viewLabel: 'titleWithId',
          column: 3
        },
      }},on:{"set-filtered-list":_vm.setFilteredList}}),_vm._v(" "),_c('div',{staticClass:"wrapperCanvas"},[(_vm.loaded)?_c('LineChart',{attrs:{"chart-data":_vm.chartData}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }