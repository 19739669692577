<script>

export default {
  name: "AddRemoveSelectedMixin",
  methods: {
    addFilterId(name, id) {
      this.createIfNotExistValueInFiltersByName(name);
      if (Array.isArray(this.selected[name])) {
        this.selected[name].push(id)
      }
    },
    addFilterSingle(name, value) {
      this.createIfNotExistValueInFiltersByName(name, null);
      this.selected[name] = value;
    },
    addFilterIdsArray(name, ids) {
      this.createIfNotExistValueInFiltersByName(name);
      if (Array.isArray(this.selected[name])) {
        ids.forEach(el => this.selected[name].push(el['id']));
      }
    },
    createIfNotExistValueInFiltersByName(name, value = []) {
      if (this.selected[name] === undefined) {
        this.$set(this.selected, name, value)
      }
    },
    removeFilterId(name, id) {
      if (Array.isArray(this.selected[name])) {
        this.selected[name] = this.selected[name].filter(el => el !== id)
      }
      if (this.selected[name] === id) {
        this.selected[name] = null;
      }
    },
    removeFilterIdsArray(name) {
      if (Array.isArray(this.selected[name])) {
        this.selected[name] = [];
      } else if (this.selected[name]) {
        this.selected[name] = null;
      }
    },
  },
}
</script>

<style scoped>

</style>
