<template>
  <div>
    <wrapper-filters
        title="Статистика по провайдерам"
        filtersUrl="/statistic/providers/getList"
        filtersUrlMethod="get"
        :setEnabledFilters="{
          date: {
            enable: true,
            column: 1,
          },
          userId: {
            enable: true,
            placeholder: 'Фильтр по юзеру',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'userId',
            data: [
              {
                id: 0,
                title: 'Общая статистика'
              },
              {
                id: 1,
                title: 'Только смс-код'
              },
              {
                id: 2,
                title: 'Только тигр'
              },
            ],
            column: 3,
            defaultValue: {
              id: 0,
              title: 'Общая статистика'
            },
            setDefaultInFilters: 0,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
                {
                  id: 1,
                  title: 'За последние 20 минут'
                },
                {
                  id: 2,
                  title: 'За последний час'
                },
                {
                  id: 3,
                  title: 'За последний день'
                },
                {
                  id: 4,
                  title: 'За последние 7 дней'
                },
                {
                  id: 5,
                  title: 'За последние 30 дней'
                },
            ],
            column: 2,
            defaultValue: {
              id: 1,
              title: 'За последние 20 минут'
            },
            setDefaultInFilters: 1
          },
        }"
        @set-filtered-list="setFilteredList"
        saveCsvUrl="providers/downloadCsvProviderStatistic"
        :enable-save-csv="true"
    >
    </wrapper-filters>
    <!-- Table -->
    <vue-good-table
        title="Service variations"
        :columns="columns"
        :rows="listToShow"
        :lineNumbers="false"
        :sort-options="{
            enabled: false,
            initialSortBy: {
              field: 'provider',
              type: 'asc'
            },
        }"
        :style-class="'vgt-table admin-table'"
        :globalSearch="true"
        :pagination-options="{
            enabled: true,
            perPage: perPage
        }"
    >
      <template v-slot:table-row="props">
        <div class="admin-table-element">
          <template v-if="props.column.field === 'service'">
            <img :src="props.row['service_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else>
            <span v-html="props.formattedRow[props.column.field]"></span>
          </template>
        </div>
      </template>
      <template v-slot:pagination-bottom="props">
        <custom-pagination
            :total="props.total"
            :pageChanged="props.pageChanged"
            :perPageChanged="props.perPageChanged"
            :perPage="perPage"
        >
        </custom-pagination>
      </template>
      <div slot="table-actions-bottom">

      </div>
    </vue-good-table>
  </div>
</template>

<script>
import CustomPagination from "../../../components/customPagination";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../../../assets/css/multiselectCustomization.css';
import BaseAdminMixin from "../mixins/BaseAdminMixin";
import Modal from '../../../components/modal';
import WrapperFilters from "../../../components/wrapperFilters"

export default {
  name: "providerServiceVariationsTable",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect,
    WrapperFilters,
  },
  data: function () {
    return {
      filteredList: [],
      columns: [
        {
          label: 'Провайдеры',
          field: 'provider',
          sortFn: function (a, b, c, row1, row2) {
            if (row1['provider_id'] < row2['provider_id']) {
              return -1
            } else if (row1['provider_id'] > row2['provider_id']) {
              return 1;
            }
            return 0;
          }
        },
        {
          label: 'Номеров отдано',
          field: 'count_received',
          width: '10%',
        },
        {
          label: 'Смс отдано',
          field: 'count_success',
          width: '10%'
        },
        {
          label: 'Доставка',
          field: 'delivery',
          width: '10%'
        },
        {
          label: 'Выручка',
          field: 'income',
          width: '10%'
        },
        {
          label: 'Расход',
          field: 'expense',
          width: '8%'
        },
        {
          label: 'Прибыль',
          field: 'profit',
          width: '10%'
        },
        {
          label: 'Маржа',
          field: 'margin',
          width: '10%'
        },
      ],
      filters: {
        dateFrom: null,
        dateTo: null,
      },
      showFilters: true,
      perPage: 200,
      timeout: null,
    };
  },
  methods: {
    setFilteredList(response) {
      this.filteredList = response
    },
    getIndexByProp(list, propKey, propValue) {
      for (let key in list) {
        if (list[key][propKey] == propValue) {
          return key;
        }
      }

      return null;
    },
  },

  computed: {
    listToShow: function () {
      let data = [];
      let sumElem = {
        provider_id: 99999,
        provider: 'Суммарно',
        count_received: 0,
        count_success: 0,
        delivery: 0,
        income: 0,
        profit: 0,
        margin: 0,
        expense: 0,
      };
      for (let elem of this.filteredList) {
        sumElem.count_received += elem['count_received'];
        sumElem.count_success += elem['count_success'];
        sumElem.income += elem['income'];
        sumElem.profit += elem['profit'];
        sumElem.expense += elem['expense'];
        data.push({
          provider_id: elem['provider']['id'],
          provider: elem['provider']['title'] + ' (' + elem['provider']['id'] + ')',
          count_received: Number(elem['count_received']).toFixed(0),
          count_success: Number(elem['count_success']).toFixed(0),
          delivery: Number(elem['delivery']).toFixed(2) + '%',
          income: Number(elem['income']).toFixed(2),
          profit: Number(elem['profit']).toFixed(2),
          expense: elem['expense'] ? Number(elem['expense']).toFixed(2) : 0,
          margin: Number(elem['margin']).toFixed(2) + '%',
        });
      }
      if (this.filteredList.length) {
        sumElem.delivery = Number(sumElem.count_success * 100 / sumElem.count_received).toFixed(2) + '%';
        if (sumElem.income) {
          sumElem.margin = Number(sumElem.profit * 100 / sumElem.income).toFixed(2) + '%';
        } else {
          sumElem.margin = 0 + '%';
        }
        sumElem.income = Number(sumElem.income).toFixed(2);
        sumElem.profit = Number(sumElem.profit).toFixed(2);
        sumElem.expense = Number(sumElem.expense).toFixed(2);
        data.push({provider: '&nbsp;'});
        data.push(sumElem);
      }
      return data;
    },
  },
}
</script>

<style scoped>

</style>
