<script>
import AjaxClient from "../../helpers/AjaxClient";

export default {
  name: "BaseSendRequestMixin",
  data: function () {
    return {
      stopRequest: null,
    }
  },
  methods: {
    async sendRequest(url, method = 'get', data = {}, config = {}) {
      let response;
      try {
        response = await new AjaxClient(this).send(
            url,
            method,
            data,
            config
        );
      } catch (e) {
        await this.alertMessage(e, 'error');
        return false;
      }
      return response;
    },

    async doWithStopRequest(callback, message) {
      if (this.stopRequest) {
        await this.alertMessage(
            this.stopRequest,
            'error'
        );
        return false;
      }
      this.stopRequest = 'В данный момент выполняется запрос. Пожалуйста подождите. ' + message;
      let result = await callback();
      this.stopRequest = null;

      return result;
    },
  }
}
</script>

<style scoped>

</style>
