import { render, staticRenderFns } from "./dynamicTopUpStatistic.vue?vue&type=template&id=254619a3&scoped=true&"
import script from "./dynamicTopUpStatistic.vue?vue&type=script&lang=js&"
export * from "./dynamicTopUpStatistic.vue?vue&type=script&lang=js&"
import style0 from "./dynamicTopUpStatistic.vue?vue&type=style&index=0&id=254619a3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254619a3",
  null
  
)

export default component.exports