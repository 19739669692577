<script>
import vNotification from "../vNotification";

export default {
  name: "BaseMessagingMixin",
  components: {
    vNotification
  },
  methods: {
    alertMessage: async function (message, type) {
      this.$notify({
        text: message,
        type: type,
      });
    },
  },
}
</script>

<style scoped>

</style>