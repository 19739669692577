<template>
  <div v-if="this.object.upperSpanText !== undefined" class="fx align-center">
    <span>{{this.object.upperSpanText}}</span>
    <label :class="this.object.simpleInputTypeClass">
      <input :type="this.object.simpleInputType"
             @change="addFilterComponentValueSimple($event)"
             :placeholder="this.object.placeholder"
             v-model="filteredList"
      >
      <span :class="this.object.bottomSpanClass ? this.object.bottomSpanClass : ''"></span>
    </label>
  </div>
  <label v-else-if="this.object.simpleInputType !== undefined" class="form__field">
    <input :type="this.object.simpleInputType"
           @input="addFilterComponentValueSimple($event)"
           :placeholder="this.object.placeholder"
           v-model="filteredList"
    />
  </label>
  <multiselect v-else-if="this.object.noGroupSelect === true"
               v-model="filteredList"
               @select="addFilterIdComponentHandler($event)"
               :allow-empty="this.object.allowEmpty !== undefined ? this.object.allowEmpty : true"
               @remove="removeFilterIdComponentHandler($event)"
               :clear-on-select="this.object.clearOnSelect !== undefined ? this.object.clearOnSelect : true"
               :placeholder="this.object.placeholder"
               :label="this.object.label  ? this.object.label: 'title'"
               :track-by="this.object.id ? this.object.id : 'id'"
               :closeOnSelect="this.object.closeOnSelect !== undefined ? this.object.closeOnSelect : false"
               :showLabels="this.object.showLabels !== undefined ? this.object.showLabels: false"
               :options="renderList"
               :multiple="this.object.multiple !== undefined ? this.object.multiple : false"
               :openDirection="this.object.openDirection ? this.object.openDirection : 'bottom'"
  ></multiselect>
  <multiselect v-else
               v-model="filteredList"
               @select="addFilterIdComponentHandler($event)"
               @remove="removeFilterIdComponentHandler($event)"
               :clear-on-select="this.object.clearOnSelect !== undefined ? this.object.clearOnSelect : true"
               :placeholder="this.object.placeholder"
               :label="this.object.label  ? this.object.label: 'title'"
               :track-by="this.object.id ? this.object.id : 'id'"
               :closeOnSelect="this.object.closeOnSelect !== undefined ? this.object.closeOnSelect : false"
               group-values="list"
               group-label="group"
               :group-select="true"
               :showLabels="this.object.showLabels !== undefined ? this.object.showLabels: false"
               :options="prepareRenderOptions()"
               :multiple="this.object.multiple !== undefined ? this.object.multiple : false"
               :openDirection="this.object.openDirection ? this.object.openDirection : 'bottom'"
  ></multiselect>
</template>

<script>
import BaseAdminMixin from "../admin/components/mixins/BaseAdminMixin";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../assets/css/multiselectCustomization.css';

export default {
  name: "oneInstanceInputComponent",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    Multiselect,
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    index: {
      type: String,
      required: true
    },
  },
  async mounted() {
    this.hostNameOrigin = window.location.origin;
    await this.updateSelectorFilters();
  },

  data() {
    return {
      filteredList: [],
      renderList: [],
      hostNameOrigin: null,
      keyNameForLabels: 'title'
    }
  },
  methods: {
    clearFilteredData() {
      this.filteredList = [];
    },
    async updateSelectorFilters() {
      if (this.object.defaultValue) {
        this.filteredList = this.object.defaultValue
      }
      if (this.object.specifyKeyForLabel) {
        this.keyNameForLabels = this.object.specifyKeyForLabel
      }
      if (this.object.setDefaultInFilters !== undefined) {
        this.$emit('add-filter-data-single', this.object.selectedKey, this.object.setDefaultInFilters)
      }
      if (this.object.data) {
        this.renderList = this.object.data;
      } else if (this.object.getDataLink) {
        this.renderList = await this.sendRequest(this.object.getDataLink);
      }
    },
    addFilterComponentValueSimple(event) {
      let value = event.target.value
      if (event.target.type === 'checkbox'){
        value = event.target.checked
      }
      return this.$emit('add-filter-data-single', this.object.selectedKey, value);
    },
    addFilterIdComponentHandler(event) {
      if (!this.object.multiple) {
        return this.$emit('add-filter-data-single', this.object.selectedKey, event.id);
      }
      if (Array.isArray(event)) {
        return this.$emit('add-filter-ids-arr', this.object.selectedKey, event);
      }
      this.$emit('add-filter-id-arr-single', this.object.selectedKey, event.id);
    },
    removeFilterIdComponentHandler(event) {
      if (Array.isArray(event)) {
        return this.$emit('remove-filter-ids-arr', this.object.selectedKey);
      }
      this.$emit('remove-filter-id', this.object.selectedKey, event.id);

    },
    getTypeRender() {
      if (this.object.viewLabel && this.object.viewLabel === 'titleWithId') {
        return this.labelTitleWithId;
      }
      return this.labelTitle;
    },
    prepareRenderOptions() {
      return [
        {
          group: 'All',
          list: this.getTypeRender()
        },
      ];
    }
  },
  computed: {
    labelTitle() {
      let data = [];
      for (let elem of this.renderList) {
        data.push({
          id: elem['id'],
          title: elem[this.keyNameForLabels],
        });
      }
      return data;
    },
    labelTitleWithId() {
      let data = [];
      for (let elem of this.renderList) {
        data.push({
          id: elem['id'],
          title: elem[this.keyNameForLabels] + ' (' + elem['id'] + ')',
        });
      }
      return data;
    },
  },
}
</script>

<style scoped>

</style>
