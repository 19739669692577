<template>
  <div>
    <div class="fx fx-start align-center title">
      <h1>{{ userList['name'] }}</h1>
      <div class="ml-20">
        <vue-spinner color="#1890ffb2" v-if="showLoader"></vue-spinner>
      </div>
    </div>
    <v-notification>
    </v-notification>
    <Modal
      v-show="showModalAdd"
      :show="showModalAdd"
      :scrollable="false"
      header-id="modalHeader"
      body-id="modalBody"
      @close="toggleShowModalAdd"
    >
      <template #header>
        Добавить розничные цены
      </template>
      <template #body>
        <multiselect
          v-model="selectedUserToAdd"
          placeholder="Выберите юзера"
          label="title"
          track-by="id"
          :closeOnSelect="false"
          group-values="list"
          group-label="group"
          :group-select="true"
          :showLabels="false"
          :options="wrapperForAllSelected(userLabels)"
          :multiple="false"
          openDirection="bottom"
        ></multiselect>
        <label class="form__field">
          <input type="text" placeholder="Telegram id юзера" v-model="paramsToAdd['telegramId']"/>
        </label>
        <button class="col-12 btn-t btn--borderRed flex-center" v-on:click="addMany">Добавить</button>
      </template>
    </Modal>
    <!-- Table -->
    <vue-good-table
      title="user list"
      :columns="columns"
      :rows="listToShow"
      :lineNumbers="false"
      :sort-options="{
              enabled: true,
            }"
      :style-class="'vgt-table admin-table'"
      :globalSearch="true"
      :pagination-options="{
              enabled: true,
              perPage: perPage
            }"
    >
      <template v-slot:table-actions>
        <div class="topping__btns fx">
          <button class="btn-t btn--borderRed flex-center" v-on:click="toggleShowModalAdd">Добавить</button>
        </div>
      </template>
      <template v-slot:table-row="props">
        <div class="admin-table-element">
          <template v-if="props.column.field === 'telegram_id'">
            <input @keyup.enter="changeItemWithLoader(props.row['id'], {telegram_id:$event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'delete'">
            <button class="btn btn-danger w-100" @click="deleteUserFromAlert(props.row['id'])">Delete</button>
          </template>
          <template v-else-if="props.column.field === 'is_active'">
            <button v-if="props.formattedRow[props.column.field]" class="btn btn-success w-100"
                    @click="changeItemWithLoader(props.row['id'], {is_active: false})">ON
            </button>
            <button v-else class="btn btn-danger w-100"
                    @click="changeItemWithLoader(props.row['id'], {is_active: true})">OFF
            </button>
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
      <template v-slot:pagination-bottom="props">
        <custom-pagination
          :total="props.total"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
          :perPage="perPage"
        >
        </custom-pagination>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import CustomPagination from "../../../components/customPagination";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../../../assets/css/multiselectCustomization.css';
import BaseAdminMixin from "../mixins/BaseAdminMixin";
import Modal from '../../../components/modal';

export default {
  name: "userListTable",
  mixins: [
    BaseAdminMixin,
  ],
  props: [
    'userList',
    'alertId',
    'allUsers',
  ],
  components: {
    CustomPagination,
    Multiselect,
    Modal
  },
  data: function () {
    return {
      filteredList: [],
      columns: [
        {
          label: 'Id',
          field: 'id',
          width: '20%',
          html: true
        },
        {
          label: 'Логин',
          field: 'login',
          width: '20%',
          html: true
        },
        {
          label: 'telegram_id',
          field: 'telegram_id',
          width: '20%',
          html: true
        },
        {
          label: 'Время добавления',
          field: 'created_at',
          width: '20%',
          html: true
        },
        {
          label: 'Активность',
          field: 'is_active',
          width: '20%',
          html: true
        },
        {
          label: 'Удалить',
          field: 'delete',
          width: '20%',
          html: true
        },
      ],
      perPage: 25,
      showModalAdd: false,
      paramsToAdd: {
        telegramId: null,
        userId: null,
        alertId: null,
        is_active: null
      },
      selectedUserToAdd: [],
    };
  },

  async mounted() {
    let data = [];
    for (let elem of this.userList['user_telegram']) {
      data.push({
        id: elem['id'],
        login: elem['user']['login'],
        created_at: elem['created_at'],
        is_active: elem['is_active'],
        telegram_id: elem['telegram_id']
      });
    }
    this.filteredList = data;
  },
  methods: {
    updateUserAlertList(newList) {
      for (let elem of newList) {
        this.filteredList.push({
          login: elem['user']['login'],
          id: elem['id'],
          created_at: elem['created_at'],
          telegram_id: elem['telegram_id'],
          is_active: elem['is_active']
        })
      }
    },
    async deleteUserFromAlert(userAlertId) {
      if (confirm('Вы уверены что хотите открепить юзера от алерта?')) {
        let response = await this.sendRequest(
          '/telegram/deleteById',
          'delete',
          this.clearObjects({
            userAlertId: userAlertId,
          }),
        );
        if (response['status'] == 'ok') {
          let index = this.userList['user_telegram'].map(x => {
            return x.id;
          }).indexOf(userAlertId);
          this.filteredList.splice(index, 1);
          await this.alertMessage(
            'Юзер был удален',
            'info'
          );
        }
      }
    },

    async changeItemWithLoader(id, params) {
      let index = this.getIndexByProp(this.filteredList, 'id', id);
      let oldObj = this.filteredList[index];
      let newObj = Object.assign(this.filteredList[index], params);
      this.filteredList[index] = newObj
      let self = this;
      let res = await this.doWithStopRequest(() => self.doWithLoader(async () => {
        let response = await this.sendRequest(
          '/telegram/updateTelegramUser',
          'patch',
          newObj
        );
        if (response) {
          this.filteredList[index] = newObj;
          await this.alertMessage(
            'Информация о юзере обновлена',
            'info'
          );
        } else {
          this.filteredList[index] = oldObj;
        }
      }), 'Изменение сервиса')
    },

    async addMany() {
      let response = await this.sendRequest(
        '/telegram/addUserToAlert',
        'put',
        this.clearObjects(this.paramsToAdd),
      );

      if (response) {
        this.updateUserAlertList(response['data']);
        await this.alertMessage('Юзер успешно прикреплен к алерту');
        this.toggleShowModalAdd();
      }
    },


    async resetObject(object) {
      for (let prop in object) {
        if (Array.isArray(object[prop])) {
          object[prop] = [];
        } else {
          object[prop] = null;
        }
      }
    },

    clearObjects(filters) {
      let data = {};
      for (let filter in filters) {
        if (Array.isArray(filters[filter]) && filters[filter].length) {
          data[filter] = filters[filter];
        } else if (filters[filter] !== null) {
          data[filter] = filters[filter];
        }
      }
      return data;
    },

    getIndexByProp(list, propKey, propValue) {
      for (let key in list) {
        if (list[key][propKey] == propValue) {
          return key;
        }
      }

      return null;
    },

    wrapperForAllSelected: function (data) {
      return [
        {
          group: 'Все',
          list: data
        },
      ];
    },

    toggleShowModalAdd() {
      this.showModalAdd = !this.showModalAdd;
    },
  },

  computed: {
    userLabels: function () {
      let data = [];
      for (let elem of this.allUsers) {
        data.push({
          id: elem['id'],
          title: elem['login'] + ' (' + elem['id'] + ')',
        });
      }
      return data;
    },

    listToShow: function () {
      return this.filteredList;
    },
  },

  watch: {
    selectedUserToAdd: async function (val) {
      this.paramsToAdd['userId'] = val.id;
      this.paramsToAdd['alertId'] = this.alertId
    },
  }
}
</script>

<style scoped>

</style>
