<template>
  <div>
    <wrapper-filters
        title="Phone history"
        filtersUrl="phones/getList"
        filtersUrlMethod="get"
        :setEnabledFilters="{
          date: {
            enable: true,
            column: 1,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Period',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
                {
                  id: 1,
                  title: 'Last 20 minutes'
                },
                {
                  id: 2,
                  title: 'Last hour'
                },
                {
                  id: 3,
                  title: 'Last day'
                },
            ],
            defaultValue: {
              id: 1,
              title: 'Last 20 minutes'
            },
            column: 1,
          },
          countries: {
            enable: true,
            multiple: true,
            getDataLink: 'phonesHistory/getCountries',
            placeholder: 'Countries',
            viewLabel: 'titleWithId',
            column: 2,
          },
          services: {
            enable: true,
            multiple: true,
            getDataLink: 'phonesHistory/getServices',
            placeholder: 'Service',
            viewLabel: 'titleWithId',
            column: 2
          },
        }"
        :enableSaveCsv="true"
        saveCsvUrl="phones/downloadCsv"
        @set-filtered-list="setFilteredList"
    >
    </wrapper-filters>
    <div>
      <h3>Total numbers: {{ totalCountPhones }}</h3>
      <h3>Total price: {{ totalSumByFilters }}</h3>
    </div>
    <!-- Table -->
    <vue-good-table
        title="My phone numbers"
        :columns="columns"
        :rows="listToShow"
        :lineNumbers="true"
        :sort-options="{
            enabled: true,
          }"
        :style-class="'vgt-table admin-table'"
        :globalSearch="true"
        :pagination-options="{
              enabled: true,
              perPage: perPage,
              jumpFirstOrLast: true,
            }"
    >
      <template v-slot:table-row="props">
        <div style="font-size: 16px">
          <template v-if="props.column.field === 'phone_number'">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'country_name'">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'country_code'">
            <img :src="props.row['country_img_path']" alt="" class="vue-td-img">{{ props.row['iso'] }}
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'service'">
            <img :src="props.row['service_img_path']" class="vue-td-img" alt="" width="50px">{{ props.row['iso'] }}
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'price'">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'sms_code'">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'created_at'">
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
      <template v-slot:pagination-bottom="props">
        <custom-pagination
            :total="totalCountPhones"
            :pageChanged="props.pageChanged"
            :perPageChanged="props.perPageChanged"
            :perPage="perPage"
        >
        </custom-pagination>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import CustomPagination from "../../../components/customPagination";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../../../assets/css/multiselectCustomization.css';
import BaseAdminMixin from "../../../admin/components/mixins/BaseAdminMixin";
import WrapperFilters from "../../../components/wrapperFilters"

export default {
  name: "phoneHistoryCabinet",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect,
    WrapperFilters
  },
  data: function () {
    return {
      filteredList: [],
      columns: [
        {
          label: 'Phone number',
          field: 'phone_number',
          sortable: false,
          width: '17%'
        },
        {
          label: 'Country name',
          field: 'country_name',
          sortable: true,
          width: '14%',
        },
        {
          label: 'Country code',
          field: 'country_code',
          width: '9%',
          sortable: true,
        },
        {
          label: 'Service',
          field: 'service',
          width: '15%',
          sortable: true
        },
        {
          label: 'Price',
          field: 'price',
          width: '7%',
          sortable: true,
          sortFn: this.sortFnPrice
        },
        {
          label: 'Sms code',
          field: 'sms_code',
          width: '10%',
          sortable: false
        },
        {
          label: 'Date',
          field: 'created_at',
          width: '21%',
          sortable: false
        },
      ],
      filters: {
        dateFrom: null,
        dateTo: null,
      },
      phoneActivation: [],
      showFilters: true,
      perPage: 30,
      selectedPage: 1,
      timeout: null,
      totalCountPhones: 0,
      totalSumByFilters: null,
    };
  },

  methods: {
    async setFilteredList(response) {
      if (response['status'] === 'exceedRows') {
        await this.alertMessage(
            'Слишком много телефонов, измените фильтр или скачайте CSV',
            'info'
        );
      } else if (response) {
        this.filteredList = response['data'];
        this.totalCountPhones = response['count'];
        this.totalSumByFilters = this.splitThousands(response['sum']);
      }
    },
    splitThousands(price) {
      let text = String(price);
      return text.split("").reverse().join("")
          .replace(/(\d{3})/g, "$1 ").split("").reverse().join("");
    },

    getIndexByProp(list, propKey, propValue) {
      for (let key in list) {
        if (list[key][propKey] == propValue) {
          return key;
        }
      }

      return null;
    },

    toggleShowModalAdd() {
      this.showModalAdd = !this.showModalAdd;
    },
    sortFnPrice(x, y) {
      return (Number(x) < Number(y) ? -1 : (Number(x) > Number(y) ? 1 : 0));
    },
  },

  computed: {
    listToShow: function () {
      let data = [];
      for (let elem of this.filteredList) {
        data.push({
          phone_number: elem['phone'],
          country_name: elem['provider_service_variation']['service_variation']['country']['title'],
          country_img_path: elem['provider_service_variation']['service_variation']['country']['img_path'],
          country_code: elem['provider_service_variation']['service_variation']['country']['iso'],
          service: elem['provider_service_variation']['service_variation']['service']['title'],
          service_img_path: elem['provider_service_variation']['service_variation']['service']['img_path'],
          price: elem['price'],
          sms_code: elem['sms_code'],
          created_at: elem['created_at'],
        });
      }
      return data;
    },
  },
}
</script>

<style scoped>

</style>
