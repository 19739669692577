<template>
  <div>
    <wrapper-filters
        title="Динамика пополнений"
        filtersUrl="/statistic/dynamicTopUp/getList"
        filtersUrlMethod="get"
        :setEnabledFilters="{
          date: {
            enable: true,
            column: 1,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
                {
                  id: 4,
                  title: 'За 1 неделю'
                },
                {
                  id: 6,
                  title: 'За 2 недели'
                },
                {
                  id: 7,
                  title: 'За 3 недели'
                },
                {
                  id: 5,
                  title: 'За месяц'
                },
            ],
            column: 1,
          },
          graphType: {
            enable: true,
            placeholder: 'Группировка по: ',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'groupBy',
            data: [
                {
                  id: 1,
                  title: 'Сервис пополнения'
                },
            ],
            column: 2

          },
          topUpServices: {
            enable: true,
            multiple: true,
            getDataLink: '/statistic/dynamicTopUp/getListServices',
            placeholder: 'Сервисы пополнения',
            viewLabel: 'titleWithId',
            column: 3
          },
        }"
        @set-filtered-list="setFilteredList"
    >
    </wrapper-filters>
    <div class="wrapperCanvas">
      <LineChart
          v-if="loaded"
          :chart-data="chartData"
      ></LineChart>
    </div>

  </div>
</template>

<script>
import WrapperFilters from "../../../components/wrapperFilters"
import LineChart from "../../../components/lineChart";
import GraphDynamic from "../../../components/mixins/GraphDynamicMixin";

export default {
  name: "dynamicTopUpStatistic",
  mixins: [
    GraphDynamic,
  ],
  components: {
    WrapperFilters,
    LineChart
  },
}
</script>

<style scoped>
.wrapperCanvas {
  position: relative;
  margin: auto;
  height: 50vh;
  width: 80vw;
}
</style>
