import Vue from 'vue';
import axios from 'axios';

import UsersTable from './admin/components/users/usersTable';
import UserCard from './admin/components/users/userCard';
import ServiceVariationsTable from './admin/components/serviceVariations/serviceVariationsTable';
import ServiceEditTable from './admin/components/services/serviceEditTable';
import CountriesTable from './admin/components/countries/countriesTable';
import ProviderStatistic from "./admin/components/statistic/providerStatistic";
import UserServiceVariationStatistic from "./admin/components/statistic/userServiceVariationStatistic";
import RequestsTable from "./admin/components/requests/requestsTable";
import ProviderServiceVariationsTable from "./admin/components/providerServiceVariations/providerServiceVariationsTable";
import ProvidersTable from "./admin/components/providers/providersTable";
import UserLockedServicesTable from "./admin/components/userLockedServices/userLockedServicesTable";
import UserPricesTable from "./admin/components/userPrices/userPricesTable";
import ProviderRequestsTable from "./admin/components/providerRequests/providerRequestsTable";
import ProviderServiceVariationStatistic from "./admin/components/statistic/providerServiceVariationStatistic";
import PhoneActivationsTable from "./admin/components/phoneActivations/phoneActivationsTable";
import PhoneActivationCatchersTable from "./admin/components/phoneActivationCatchers/phoneActivationCatchersTable";
import CaughtPhoneActivationsTable from "./admin/components/caughtPhoneActivations/caughtPhoneActivationsTable";
import PhoneHistoryCabinetTable from "./web/components/phoneCabinet/phoneHistoryCabinetTable";
import UserServiceVariationFullStatistic from "./admin/components/statistic/userServiceVariationFullStatistic";
import UsersOnlyStatistic from "./admin/components/statistic/usersOnlyStatistic";
import VueGoodTable from 'vue-good-table';
import DynamicStatistic from "./admin/components/statistic/dynamicStatistic";
import RentPricesTable from "./admin/components/rentPrice/rentPricesTable";
import DynamicTopUpStatistic from "./admin/components/statistic/dynamicTopUpStatistic";
import DynamicPhoneActivationStatistic from "./admin/components/statistic/dynamicPhoneActivationStatistic";
import ProvidersBalanceTable from "./admin/components/providers/providersBalanceTable";
import ActionChronology from "./admin/components/actionChronology/actionChronologyTable";
import Telegram from "./admin/components/telegram/template/telegram"
import TelegramBotList from "./admin/components/telegram/botList"
import TelegramAlertList from "./admin/components/telegram/alertList"
import TelegramUserList from "./admin/components/telegram/userList"
import Alipay from "./admin/components/alipay";
import Notifications from 'vue-notification';
import Permissions from "./admin/components/roleAndPermissions/permissions.vue";
import EditRole from "./admin/components/roleAndPermissions/editRole.vue";
import Feedbacks from "./admin/components/feedbacks/feedbacks.vue";
import DashboardStatistic from "./admin/components/dashboard/dashboardStatistic.vue";
import UserStatisticByUtmSource from "./admin/components/statistic/userStatisticByUtmSource.vue";
import SitemapTable from "./admin/components/sitemap/sitemapTable.vue";

// import 'bootstrap-vue/dist/bootstrap-vue.css';
// import BootstrapVue from 'bootstrap-vue';

import 'vue-good-table/dist/vue-good-table.css';
import './assets/css/adminTableCustomize.css';
import SmsDelivery from "./web/components/smsDelivery";
import TopUpStatistic from "./admin/components/statistic/topUpStatistic";

axios.defaults.baseUrl = '/';

// axios.interceptors.response.use(
//   function (response) {
//     if (response.headers['content-type'] === 'text/html; charset=utf-8') {
//       location.reload();
//     }
//     return response;
//   },
// );

Vue.prototype.$axios = axios;

// Vue.use(BootstrapVue);
Vue.use(VueGoodTable);
Vue.use(Notifications);

Vue.component('users-table', UsersTable);
Vue.component('user-card', UserCard);
Vue.component('service-variations-table', ServiceVariationsTable);
Vue.component('service-edit-table', ServiceEditTable);
Vue.component('countries-table', CountriesTable);
Vue.component('provider-service-variations-table', ProviderServiceVariationsTable);
Vue.component('provider-statistic', ProviderStatistic);
Vue.component('requests-table', RequestsTable);
Vue.component('providers-table', ProvidersTable);
Vue.component('user-locked-services-table', UserLockedServicesTable);
Vue.component('user-prices-table', UserPricesTable);
Vue.component('provider-requests-table', ProviderRequestsTable);
Vue.component('user-service-variation-statistic', UserServiceVariationStatistic);
Vue.component('provider-service-variation-statistic', ProviderServiceVariationStatistic);
Vue.component('phone-activations-table', PhoneActivationsTable);
Vue.component('caught-phone-activations-table', CaughtPhoneActivationsTable);
Vue.component('phone-activation-catchers-table', PhoneActivationCatchersTable);
Vue.component('phone-history-cabinet-table', PhoneHistoryCabinetTable);
Vue.component('user-service-variation-full-statistic', UserServiceVariationFullStatistic);
Vue.component('sms-delivery', SmsDelivery);
Vue.component('dynamic-statistic', DynamicStatistic);
Vue.component('users-only-statistic', UsersOnlyStatistic);
Vue.component('rent-prices-table', RentPricesTable);
Vue.component('topup-statistic',TopUpStatistic);
Vue.component('dynamic-top-up-statistic', DynamicTopUpStatistic);
Vue.component('dynamic-phone-activation-statistic', DynamicPhoneActivationStatistic);
Vue.component('providers-balance-table', ProvidersBalanceTable);
Vue.component('action-chronology-table', ActionChronology);
Vue.component('alipay-coupons', Alipay);

Vue.component('telegram', Telegram);
Vue.component('bot-list', TelegramBotList);
Vue.component('alert-list', TelegramAlertList);
Vue.component('telegram-user-list', TelegramUserList);
Vue.component('permissions-table', Permissions);
Vue.component('edit-role-table', EditRole);
Vue.component('feedback-component', Feedbacks);
Vue.component('dashboard-statistic', DashboardStatistic);
Vue.component('user-statistic-by-utm-source', UserStatisticByUtmSource);
Vue.component('sitemap-table', SitemapTable);
new Vue({
    el: '#vue-app',
});

