<template>
  <div>
    <Modal
        v-show="showModalAdd"
        :show="showModalAdd"
        :scrollable="false"
        header-id="modalHeader"
        body-id="modalBody"
        @close="toggleShowModalAdd"
    >
      <template #header>
        Добавить розничные цены
      </template>
      <template #body>
        <multiselect
            v-model="selectedServicesToAdd"
            placeholder="Выберите сервис"
            label="title"
            track-by="id"
            :closeOnSelect="false"
            group-values="list"
            group-label="group"
            :group-select="true"
            :showLabels="false"
            :options="wrapperForAllSelected(serviceLabels)"
            :multiple="true"
            openDirection="bottom"
        ></multiselect>
        <multiselect
            v-model="selectedCountriesToAdd"
            placeholder="Выберите страну"
            label="title"
            track-by="id"
            :closeOnSelect="false"
            group-values="list"
            group-label="group"
            :group-select="true"
            :showLabels="false"
            :options="wrapperForAllSelected(countryLabels)"
            :multiple="true"
            openDirection="bottom"
        ></multiselect>
        <label class="form__field">
          <input type="text" placeholder="Цена" v-model="paramsToAdd['price']"/>
        </label>
        <button class="col-12 btn-t btn--borderRed flex-center" v-on:click="addMany">Добавить</button>
      </template>
    </Modal>
    <v-notification>
    </v-notification>
    <wrapper-filters
        title="Розничные цены"
        filtersUrl="/serviceVariations/getList"
        filtersUrlMethod="get"
        :setEnabledFilters="{
          countries: {
            enable: true,
            multiple: true,
            getDataLink: '/countries/getList',
            placeholder: 'Страны',
            viewLabel: 'titleWithId',
            column: 1,
          },
          services: {
            enable: true,
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Сервисы',
            viewLabel: 'titleWithId',
            column: 1
          },

          priceFrom: {
            enable: true,
            simpleInputType: 'text',
            placeholder: 'Цена (от)',
            selectedKey: 'priceFrom',
            column: 2
          },
          priceTo:{
            enable: true,
            simpleInputType: 'text',
            placeholder: 'Цена (до)',
            selectedKey: 'priceTo',
            column: 2
          },
        }"
        @set-filtered-list="setFilteredList"
        saveCsvUrl="/serviceVariations/downloadCsvServiceVariation"
        :enable-save-csv="true"
    >
    </wrapper-filters>
    <!-- Table -->
    <vue-good-table
        title="Service variations"
        :columns="columns"
        :rows="listToShow"
        :lineNumbers="false"
        :sort-options="{
              enabled: true,
            }"
        :style-class="'vgt-table admin-table'"
        :globalSearch="true"
        :pagination-options="{
              enabled: true,
              perPage: perPage
            }"
    >
      <template v-slot:table-actions>
        <div class="topping__btns fx">
          <button class="btn-t btn--borderRed flex-center" v-on:click="toggleShowModalAdd">Добавить</button>
          <label class="form__field mr-10">
            <input type="text" placeholder="Новая цена для всех в списке" v-model="newPriceModel" @keyup.enter="changeAllWithLoader({price: $event.target.value}); newPriceModel = null;"/>
          </label>
          <button class="btn-t btn--borderRed flex-center" v-on:click="changeAllWithLoader({price: newPriceModel}); newPriceModel = null;">Обновить цену</button>
        </div>
      </template>
      <template v-slot:table-row="props">
        <div class="admin-table-element">
          <template v-if="props.column.field === 'service'">
            <img :src="props.row['service_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'country'">
            <img :src="props.row['country_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'price'">
            <input @keyup.enter="changeItemWithLoader(props.row['id'], {price: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
      <template v-slot:pagination-bottom="props">
        <custom-pagination
            :total="props.total"
            :pageChanged="props.pageChanged"
            :perPageChanged="props.perPageChanged"
            :perPage="perPage"
        >
        </custom-pagination>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import CustomPagination from "../../../components/customPagination";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../../../assets/css/multiselectCustomization.css';
import BaseAdminMixin from "../mixins/BaseAdminMixin";
import Modal from '../../../components/modal';
import WrapperFilters from "../../../components/wrapperFilters"

export default {
  name: "serviceVariationsTable",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect,
    Modal,
    WrapperFilters
  },
  data: function () {
    return {
      filteredList: [],
      columns: [
        {
          label: 'Страна',
          field: 'country',
          width: '15%',
          html: true
        },
        {
          label: 'Сервис',
          field: 'service',
          width: '15%',
          html: true
        },
        {
          label: 'Цена',
          field: 'price',
          width: '11%',
          sortFn: this.sortNumber,
          sortable: true,
          html: true
        },
        // {
        //   label: 'Оптовые цены',
        //   field: 'wholesale_price',
        //   width: '42%',
        //   html: true
        // },
        {
          label: 'Обновлено',
          field: 'updated_at',
          width: '18%',
        },
      ],
      filters: {
        dateFrom: null,
        dateTo: null,
      },
      showFilters: true,
      perPage: 25,
      newPriceModel: null,
      timeout: null,
      countries: [],
      services: [],
      showModalAdd: false,
      paramsToAdd: {
        countriesIds: null,
        servicesIds: null,
        price: null,
      },
      selectedServicesToAdd: [],
      selectedCountriesToAdd: [],
    };
  },

  async mounted() {
    await this.updateSelectorFilters();
  },

  methods: {
    setFilteredList(response) {
      this.filteredList = response;
    },
    async changeItemWithLoader(id, params)
    {
      let index = this.getIndexByProp(this.filteredList, 'id', id);
      let oldObj = this.filteredList[index];
      let newObj = Object.assign(this.filteredList[index], params);
      this.filteredList[index] = newObj
      let self = this;
      await this.doWithStopRequest(() => self.doWithLoader(async () => {
        let response = await this.sendRequest(
            '/serviceVariations',
            'patch',
            newObj
        );

        if (response) {
          this.filteredList[index] = newObj;
          await this.alertMessage(
              'Item has been changed',
              'info'
          );
        } else {
          this.filteredList[index] = oldObj;
        }
      }), 'Изменение сервиса')
    },

    async changeAllWithLoader(data)
    {
      let self = this;
      await this.doWithStopRequest(() => self.doWithLoader(async () => {
        let filteredServiceVariationsIds = this.filteredList.map(function (elem) {
          return elem['id'];
        });

        if (confirm('Вы уверены что хотите поменять ' + filteredServiceVariationsIds.length + ' вариаций?')) {
          let params = data;
          params['ids'] = filteredServiceVariationsIds;
          let response = await this.sendRequest(
              '/serviceVariations/editMany',
              'patch',
              params
          );

          if (response) {
            this.filteredList = this.filteredList.map(function (elem) {
              elem = Object.assign(elem, data);
              return elem;
            });
            await this.alertMessage(
                'Items have been changed',
                'info'
            );
          }
        }
      }), 'Изменение сервисов')
    },

    async addMany()
    {
      let response = await this.sendRequest(
          '/serviceVariations/addMany',
          'put',
          this.clearObjects(this.paramsToAdd),
      );

      if (response) {
        this.filteredList.unshift(...response['data']);
        await this.alertMessage('Service variations successfully added');
        this.toggleShowModalAdd();
      }
    },
    async updateSelectorFilters()
    {
      this.countries = await this.sendRequest('/countries/getList');
      this.services = await this.sendRequest('/services/getList');
    },

    clearObjects(filters)
    {
      let data = {};
      for (let filter in filters) {
        if (Array.isArray(filters[filter]) && filters[filter].length) {
          data[filter] = filters[filter];
        } else if (filters[filter] !== null) {
          data[filter] = filters[filter];
        }
      }
      return data;
    },

    getIndexByProp(list, propKey, propValue) {
      for (let key in list) {
        if (list[key][propKey] == propValue) {
          return key;
        }
      }

      return null;
    },

    wrapperForAllSelected: function (data) {
      return [
        {
          group: 'Все',
          list: data
        },
      ];
    },

    toggleShowModalAdd() {
      this.showModalAdd = !this.showModalAdd;
    },
  },

  computed: {
    listToShow: function () {
      let data = [];
      for (let elem of this.filteredList) {
        data.push({
          id: elem['id'],
          updated_at: elem['updated_at'],
          price: Number(elem['price']).toFixed(2),
          // provider_service_variations: elem['provider_service_variations'],
          country: elem['country']['title'] + ' (' + elem['country']['id'] + ')',
          country_img_path: elem['country']['img_path'],
          service: elem['service']['title'] + ' (' + elem['service']['id'] + ')',
          service_img_path: elem['service']['img_path'],
        });
      }
      return data;
    },

    serviceLabels: function () {
      let data = [];
      for (let elem of this.services) {
        data.push({
          id: elem['id'],
          title: elem['title'] + ' (' + elem['id'] + ')',
        });
      }
      return data;
    },

    countryLabels: function () {
      let data = [];
      for (let elem of this.countries) {
        data.push({
          id: elem['id'],
          title: elem['title'] + ' (' + elem['id'] + ')',
        });
      }
      return data;
    },
  },

  watch: {
    selectedCountriesToAdd: async function (val) {
      this.paramsToAdd['countriesIds'] = val.map(function (elem) {
        return elem['id'];
      });
    },

    selectedServicesToAdd: async function (val) {
      this.paramsToAdd['servicesIds'] = val.map(function (elem) {
        return elem['id'];
      });
    },
  }
}
</script>

<style scoped>

</style>
