var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('wrapper-filters',{attrs:{"title":"Статистика по провайдерам","filtersUrl":"/statistic/providers/getList","filtersUrlMethod":"get","setEnabledFilters":{
        date: {
          enable: true,
          column: 1,
        },
        userId: {
          enable: true,
          placeholder: 'Фильтр по юзеру',
          noGroupSelect: true,
          closeOnSelect: true,
          selectedKey: 'userId',
          data: [
            {
              id: 0,
              title: 'Общая статистика'
            },
            {
              id: 1,
              title: 'Только смс-код'
            },
            {
              id: 2,
              title: 'Только тигр'
            },
          ],
          column: 3,
          defaultValue: {
            id: 0,
            title: 'Общая статистика'
          },
          setDefaultInFilters: 0,
        },
        dateSelector: {
          enable: true,
          placeholder: 'Период',
          noGroupSelect: true,
          closeOnSelect: true,
          selectedKey: 'dateSelector',
          data: [
              {
                id: 1,
                title: 'За последние 20 минут'
              },
              {
                id: 2,
                title: 'За последний час'
              },
              {
                id: 3,
                title: 'За последний день'
              },
              {
                id: 4,
                title: 'За последние 7 дней'
              },
              {
                id: 5,
                title: 'За последние 30 дней'
              },
          ],
          column: 2,
          defaultValue: {
            id: 1,
            title: 'За последние 20 минут'
          },
          setDefaultInFilters: 1
        },
      },"saveCsvUrl":"providers/downloadCsvProviderStatistic","enable-save-csv":true},on:{"set-filtered-list":_vm.setFilteredList}}),_vm._v(" "),_c('vue-good-table',{attrs:{"title":"Service variations","columns":_vm.columns,"rows":_vm.listToShow,"lineNumbers":false,"sort-options":{
          enabled: false,
          initialSortBy: {
            field: 'provider',
            type: 'asc'
          },
      },"style-class":'vgt-table admin-table',"globalSearch":true,"pagination-options":{
          enabled: true,
          perPage: _vm.perPage
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_c('div',{staticClass:"admin-table-element"},[(props.column.field === 'service')?[_c('img',{attrs:{"src":props.row['service_img_path'],"alt":"","width":"20px"}}),_vm._v("\n          "+_vm._s(props.formattedRow[props.column.field])+"\n        ")]:[_c('span',{domProps:{"innerHTML":_vm._s(props.formattedRow[props.column.field])}})]],2)]}},{key:"pagination-bottom",fn:function(props){return [_c('custom-pagination',{attrs:{"total":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged,"perPage":_vm.perPage}})]}}])},[_vm._v(" "),_vm._v(" "),_c('div',{attrs:{"slot":"table-actions-bottom"},slot:"table-actions-bottom"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }