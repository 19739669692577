<template>
  <div>
    <base-table
        :columns="this.columns"
        :per-page="300"
        :startWithData="preparedFiltersList"
        :wrapperFiltersData="this.wrapperFiltersData"
        :changeItemWithLoaderUrl="changeItemWithLoaderUrl"
        :changeItemWithLoaderMethod="changeItemWithLoaderMethod"
        :render-list-to-show="this.listToShow"
    >
      <template v-slot:base-table-row="props">
        <div class="admin-table-element" style="font-size: 18px">
          <template v-if="props.column.field === 'alert_min_balance'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {alert_min_balance: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else>
            <span :class="getClass(props.formattedRow)">{{ props.formattedRow[props.column.field] }}</span>
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from "../../../components/baseTable";

export default {
  name: "providerBalances",
  components: {
    BaseTable,
  },
  props: ['indexData'],
  data: function () {
    return {
      wrapperFiltersData: {
        title: "Балансы провайдеров",
      },
      columns: [
        {
          label: 'ID⠀⠀',
          field: 'id',
          sortFn: 'sortNumber',
          width: '5%',
        },
        {
          label: 'Баланс',
          field: 'balance',
          sortFn: 'sortFloatWithThousandsSeparator',
          width: '8%',
        },
        {
          label: 'Валюта',
          field: 'currency',
          width: '10%',
          sortable: false
        },
        {
          label: 'Баланс руб',
          field: 'balance_rub',
          sortFn: 'sortFloatWithThousandsSeparator',
          width: '10%',
        },
        {
          label: 'Мин баланс для алерта',
          field: 'alert_min_balance',
          width: '7%',
          sortable: false
        },
      ],
      changeItemWithLoaderUrl: '/statistic/providers/balances/edit',
      changeItemWithLoaderMethod: 'post',
      preparedFiltersList: []
    };
  },
  created() {
    for (let i of this.indexData){
      this.preparedFiltersList.push(i);
    }
    console.log(this.preparedFiltersList);
  },
  methods: {
    listToShow(list) {
      let data = [];
      list = this.indexData;
      for (let elem in list) {
        data.push({
          id: list[elem]['id'],
          balance: list[elem]['balance'],
          balance_rub: list[elem]['balance_rub'],
          currency: list[elem]['currency'],
          alert_min_balance: list[elem]['alert_min_balance'],
        });
      }
      return data;
    },
    getClass(row) {
      let balance = parseInt(row['balance_rub'].replace(/\s/g, ""));
      if (isNaN(balance)){
        return '';
      }
      if (balance < row['alert_min_balance']){
        return 'colorRed';
      }
      if (balance < (row['alert_min_balance'] * 3)) {
        return 'colorOrange';
      }
      return '';
    }
  },
}
</script>

<style scoped>
.colorOrange {
  color: #e0930a;
}
.colorRed {
  color: #ff071b;
}

</style>
