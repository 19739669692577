<template>
  <div>
    <div class="fx fx-start align-center title">
      <h1>Сервисы</h1>
      <div class="ml-20">
        <vue-spinner color="#1890ffb2" v-if="showLoader"></vue-spinner>
      </div>
    </div>
    <v-notification>
    </v-notification>
    <Modal
        v-show="showModalAdd"
        :show="showModalAdd"
        :scrollable="false"
        header-id="modalHeader"
        body-id="modalBody"
        @close="toggleShowModalAdd"
    >
      <template #header>
        Добавить новый сервис
      </template>
      <template #body>
        <label class="form__field">
          <input type="text" placeholder="Название" v-model="paramsToAdd['title']"/>
        </label>
        <button class="col-12 btn-t btn--borderRed flex-center" v-on:click="add">Добавить</button>
      </template>
    </Modal>
    <!-- Table -->
    <vue-good-table
        title="Service list"
        :columns="columns"
        :rows="listToShow"
        :lineNumbers="false"
        :sort-options="{
          enabled: true,
          initialSortBy: {
            field: 'id',
            type: 'asc'
          },
        }"
        :style-class="'vgt-table admin-table'"
        :globalSearch="true"
        :pagination-options="{
          enabled: true,
          perPage: perPage
        }"
    >
      <template v-slot:table-actions>
        <div class="topping__btns fx">
          <button class="btn-t btn--borderRed flex-center" v-on:click="toggleShowModalAdd">Добавить сервис</button>
        </div>
      </template>
      <template v-slot:table-row="props">
        <div class="admin-table-element">
          <template v-if="props.column.field === 'title'">
            <img :src="props.row['service_img_path']" alt="" width="20px">
            <input @keyup.enter="changeItemWithLoader(props.row['id'], {title: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'ksms_id'">
            <input @keyup.enter="changeItemWithLoader(props.row['id'], {ksms_id: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'sms_activate_org_code'">
            <input @keyup.enter="changeItemWithLoader(props.row['id'], {sms_activate_org_code: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'delete'">
            <button class="btn btn-danger w-100" @click="deleteById(props.row['id'])">Delete</button>
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
      <template v-slot:pagination-bottom="props">
        <custom-pagination
            :total="props.total"
            :pageChanged="props.pageChanged"
            :perPageChanged="props.perPageChanged"
            :perPage="perPage"
        >
        </custom-pagination>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import CustomPagination from "../../../components/customPagination";
import BaseAdminMixin from "../mixins/BaseAdminMixin";
import Modal from '../../../components/modal';

export default {
  name: "serviceEditTable",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Modal
  },
  data: function () {
    return {
      filteredList: [],
      columns: [
        {
          label: 'ID',
          field: 'id',
          sortFn: this.sortNumber,
          width: '15%',
          html: true
        },
        {
          label: 'Название',
          field: 'title',
          width: '30%',
          html: true
        },
        {
          label: 'ksms_id',
          field: 'ksms_id',
          width: '15%',
          html: true
        },
        {
          label: 'ID смс активейтов',
          field: 'sms_activate_org_code',
          width: '25%',
          html: true
        },
        {
          label: 'Удалить',
          field: 'delete',
          width: '20%',
        },
        {
          label: '',
          field: '',
          width: '1%',
          sortable: false,
        }
      ],
      perPage: 500,
      filters: {},
      showModalAdd: false,
      paramsToAdd: {
        title: null,
      },
    };
  },

  async mounted() {
    await this.updateByFiltersWithPause()
  },

  methods: {
    async deleteById(id)
    {
      let self = this;
      await this.doWithStopRequest(() => self.doWithLoader(async () => {
        let response = await this.sendRequest(
          '/services/deleteById',
          'delete',
          {
            id: id
          }
        );
        if (response['status'] == 'ok') {
          let index = this.getIndexByProp(this.filteredList, 'id', id);
          this.filteredList.splice(index, 1);
          await this.alertMessage(
            'Item has been deleted',
            'info'
          );
        }else{
          await this.alertMessage(
            response['status'],
            'error'
          );
        }
      }), 'Удаление сервиса')
    },
    async updateByFiltersWithPause() {
      clearTimeout(this.timeout);
      let self = this;
      this.timeout = setTimeout(
          () => self.doWithStopRequest(() => self.doWithLoader(() => self.updateByFilters()), 'Фильтрация'),
          1
      );
    },

    async updateByFilters() {
      // if (!this.isListFiltered) {
      //     this.filteredList = [];
      //     return;
      // }
      let response = await this.sendRequest(
          '/services/getList',
          'get',
          this.clearObjects(this.filters),
      );

      if (response) {
        this.filteredList = response;
      }
    },
    clearObjects(filters) {
      let data = {};
      for (let filter in filters) {
        if (Array.isArray(filters[filter]) && filters[filter].length) {
          data[filter] = filters[filter];
        } else if (filters[filter] !== null) {
          data[filter] = filters[filter];
        }
      }
      return data;
    },
    async changeItemWithLoader(id, params) {
      let index = this.getIndexByProp(this.filteredList, 'id', id);
      let newObj = Object.assign({}, this.filteredList[index], params);
      let cloneObj = [...this.filteredList];

      await this.doWithStopRequest(() => this.doWithLoader(async () => {
        let response = await this.sendRequest(
            '/services/',
            'patch',
            newObj
        );

        if (response) {
          this.filteredList = [];
          await this.alertMessage(
              'Item has been changed',
              'info'
          );
          this.filteredList = [...cloneObj];
          this.filteredList[index] = Object.assign({}, newObj)
        }
      }), 'Изменение сервиса')


    },
    async add() {
      let response = await this.sendRequest(
          '/services/add',
          'put',
          this.clearObjects(this.paramsToAdd),
      );
      if (response) {
        this.filteredList.push(response['data']);
        await this.alertMessage('Service successfully added');
        this.toggleShowModalAdd();
        this.resetObject(this.paramsToAdd);
      }
    }
    ,
    resetObject(object)
    {
      for (let prop in object) {
        if (Array.isArray(object[prop])) {
          object[prop] = [];
        } else {
          object[prop] = null;
        }
      }
    },

// async updateSelectorFilters()
// {
//   this.countries = await this.sendRequest('/countries/getList');
//   this.services = await this.sendRequest('/services/getList');
// },


    getIndexByProp(list, propKey, propValue) {
      for (let key in list) {
        if (list[key][propKey] == propValue) {
          return key;
        }
      }

      return null;
    },

    toggleShowModalAdd() {
      this.showModalAdd = !this.showModalAdd;
    },

    sortNumber(a, b)
    {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1
      }

      return 0;
    }
  },
  computed: {
    listToShow: function () {
      let data = [];
      for (let elem of this.filteredList) {
        data.push({
          id: elem['id'],
          title: elem['title'],
          service_img_path: elem['img_path'],
          ksms_id: elem['ksms_id'],
          sms_activate_org_code: elem['sms_activate_org_code']
        });
      }
      return data;
    }
    ,
  }
}
</script>

<style scoped>

</style>
