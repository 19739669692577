<template>
  <div>
    <div>
      <h1>
        Алерты к боту: {{ alertList['bot_name'] }}
      </h1>
    </div>
    <telegram :list-to-show="this.alertList['telegram_alert']" :href-url="'/telegram/getUsersList/'">
    </telegram>
  </div>
</template>
<script>
export default {
  name: "alert-list",
  props:[
    'alertList',
  ],
}
</script>
