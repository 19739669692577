<template>
  <div>
    <base-table
        :columns="this.columns"
        :wrapperFiltersData="this.wrapperFiltersData"
        :perPage="50"
        :render-list-to-show="this.listToShow"
    >
      <template v-slot:base-table-row="props">
        <div class="admin-table-element" style="font-size: 17px">
          <template v-if="props.column.field === 'service'">
            <img :src="props.row['service_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else-if="props.column.field === 'country'">
            <img :src="props.row['country_img_path']" alt="" width="20px">
            {{ props.formattedRow[props.column.field] }}
          </template>
          <template v-else>
            <span>{{ props.formattedRow[props.column.field] }}</span>
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from "../../../components/baseTable";

export default {
  name: "actionsChronology",
  components: {
    BaseTable
  },
  data: function () {
    return {
      wrapperFiltersData: {
        title: "Хронология действий",
        filtersUrl: "/chronology/getList",
        startWithRequest: true,
        items: {
          date: {
            enable: true,
            column: 1,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
              {
                id: 1,
                title: 'За последние 20 минут'
              },
              {
                id: 4,
                title: 'За 1 неделю'
              },
              {
                id: 6,
                title: 'За 2 недели'
              },
              {
                id: 7,
                title: 'За 3 недели'
              },
              {
                id: 5,
                title: 'За месяц'
              },
            ],
            column: 1,
            defaultValue: {
              id: 1,
              title: 'За последние 20 минут'
            },
            setDefaultInFilters: 1
          },
          countries: {
            enable: true,
            multiple: true,
            getDataLink: '/countries/getList',
            placeholder: 'Страны',
            viewLabel: 'titleWithId',
            column: 2,
          },
          services: {
            enable: true,
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Сервисы',
            viewLabel: 'titleWithId',
            column: 2
          },
          providers: {
            enable: true,
            multiple: true,
            getDataLink: '/providers/getList',
            placeholder: 'Провайдеры',
            viewLabel: 'titleWithId',
            column: 2
          },
          clients: {
            enable: true,
            multiple: true,
            getDataLink: '/users/getUsers',
            placeholder: 'Клиенты',
            specifyKeyForLabel: 'login',
            viewLabel: 'titleWithId',
            column: 3
          },
          users: {
            enable: true,
            multiple: true,
            getDataLink: '/chronology/getUserWhoChanged',
            placeholder: 'Кто изменял',
            viewLabel: 'titleWithId',
            specifyKeyForLabel: 'name',
            column: 3
          },
          actionType: {
            enable: true,
            multiple: true,
            getDataLink: '/chronology/getActionType',
            placeholder: 'Вкладка',
            column: 3
          }
        }
      },
      columns: [
        {
          label: 'Сервис',
          field: 'service',
          width: '15%',
          sortable: false,
        },
        {
          label: 'Страна',
          field: 'country',
          width: '15%',
          sortable: false,
        },
        {
          label: 'Провайдер',
          field: 'provider',
          width: '11%',
          sortable: false,
        },
        {
          label: 'Вкладка',
          field: 'tab',
          width: '15%',
          sortable: false,
        },
        {
          label: 'Ключ',
          field: 'key',
          width: '12%',
          sortable: false,
        },
        {
          label: 'Действие',
          field: 'description',
          type: 'number',
          width: '20%',
          sortable: false,
        },

        {
          label: 'Предыдущее значение',
          field: 'old_value',
          width: '15%',
          sortable: false,
        },
        {
          label: 'Новое значение',
          field: 'new_value',
          width: '13%',
          sortable: false,
        },
        {
          label: 'Клиент',
          field: 'client',
          width: '10%',
          sortable: false,
        },
        {
          label: 'Кто изменил',
          field: 'user',
          width: '10%',
          sortable: false,
        },
        {
          label: 'Дата',
          field: 'date',
          width: '20%',
        }
      ]
    };
  },
  methods: {
    listToShow(list) {
      let result = [];
      for (let elem of list) {
        result.push({
          date: elem['date'],
          tab: elem['action_type_id']['description'],
          key: elem['key'] ? elem['key'] : '--',
          old_value: elem['old_value'] ? elem['old_value'] : '--',
          new_value: elem['new_value'] ? elem['new_value'] : '--',
          user: elem['user']['name'] ? elem['user']['name'] : '--',
          description: elem['description'],
          client: elem['client'] ? elem['client']['name'] : '--',
          country: elem['country'] ? elem['country']['title'] + ' (' + elem['country']['id'] + ')' : '--',
          country_img_path: elem['country'] ? elem['country']['img_path'] : '',
          service: elem['service'] ? elem['service']['title'] + ' (' + elem['service']['id'] + ')': '--',
          provider: elem['provider'] ? elem['provider']['title'] + ' (' + elem['provider']['id'] + ')': '--',
          service_img_path: elem['service']? elem['service']['img_path'] : '',
        });
      }
      return result;
    },
  }
}
</script>

<style scoped>
</style>
