export default function ApiClient(main) {
    this.send = async function (url, method, data = {}, config = {}) {
        let response;

        try {
            if (method === 'get') {
                let queryString = getQueryString(data);
                response = await main.$axios.get(url + '?' + queryString, config);
            } else {
                config.url = url;
                config.method = method;
                config.data = data;
                response = await main.$axios(config);
            }
        } catch (e) {
            response = e.response;
        }

        if (response.data.errors) {
            let errors = [];
            if (Array.isArray(response.data.errors)) {
                errors.push(response.data.errors);
            } else if (typeof response.data.errors === 'object') {
                for (let prop in response.data.errors) {
                    for (let error of response.data.errors[prop]) {
                        errors.push(error);
                    }
                }
            } else {
                errors.push(response.data.errors);
            }

            throw errors.join('<br>');
        }

        if (response.status !== 200) {
            throw response.statusText;
        }

        if (!response.data) {
            throw 'Something went wrong. Call programmer';
        }

        return response.data;
    }

    const getQueryArray = (obj, path = [], result = []) =>
        Object.entries(obj).reduce((acc, [ k, v ]) => {
            path.push(k);

            if (v instanceof Object) {
                getQueryArray(v, path, acc);
            } else {
                acc.push(`${path.map((n, i) => i ? `[${n}]` : n).join('')}=${v}`);
            }

            path.pop();

            return acc;
        }, result);

    const getQueryString = obj => getQueryArray(obj).join('&');
}