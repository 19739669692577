<template>
  <div>
    <base-table
      :columns="this.columns"
      :wrapperFiltersData="this.wrapperFiltersData"
      :addModalInputData="this.addModalInputData"
      :changeAllWithLoaderUrl="changeAllWithLoaderUrl"
      :changeItemWithLoaderMethod="changeItemWithLoaderMethod"
      :changeItemWithLoaderUrl="changeItemWithLoaderUrl"
      :changeAllWithLoaderMethod="changeAllWithLoaderMethod"
      :renderListToShow="this.listToShow"
    >
      <template v-slot:base-table-actions="props">

        <div class="topping__btns fx fx-end col-12">
          <label class="form__field mr-10">
            <input type="text" placeholder="Новая цена для всех в списке" v-model="newPriceModel"
                   @keyup.enter="props.changeAllWithLoader({wholesale_price: newPriceModel});  newPriceModel = null;"/>
          </label>
          <button class="btn-t btn--borderRed flex-center"
                  @click="props.changeAllWithLoader({wholesale_price: newPriceModel}); newPriceModel = null;">Обновить
            цену
          </button>
          <label class="form__field mr-10">
            <input type="text" placeholder="Новая цена поставщиков" v-model="newProviderPriceModel"
                   @keyup.enter="props.changeAllWithLoader({supplier_price: newProviderPriceModel});  newProviderPriceModel = null;"/>
          </label>
          <button class="btn-t btn--borderRed flex-center"
                  @click="props.changeAllWithLoader({supplier_price: newProviderPriceModel}); newProviderPriceModel = null;">
            Обновить
            цену поставщика
          </button>
        </div>
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderRed flex-center" @click="props.toggleShowModalAdd">Добавить</button>
          <button class="btn-t btn--borderRed flex-center" @click="props.changeAllWithLoader({is_active: true})">Акт.
            все
          </button>
          <button class="btn-t btn--borderRed flex-center" @click="props.changeAllWithLoader({is_active: false})">Деакт.
            все
          </button>
          <button class="btn-t btn--borderRed flex-center"
                  @click="props.changeAllWithLoader({allow_multiple_sms: true})">
            Неск. смс все
          </button>
          <button class="btn-t btn--borderRed flex-center"
                  @click="props.changeAllWithLoader({allow_multiple_sms: false})">Одна смс все
          </button>
          <label class="form__field mr-10">
            <input type="text" placeholder="Приоритет для всех" v-model="newRatioModel"
                   @keyup.enter="props.changeAllWithLoader({ratio: newRatioModel});  newRatioModel = null;"/>
          </label>
          <label class="form__field mr-10">
            <input type="text" placeholder="Код сервиса для всех" v-model="newCodeModel"
                   @keyup.enter="props.changeAllWithLoader({service_code: newCodeModel});  newCodeModel = null;"/>
          </label>
          <label class="form__field mr-10">
            <input type="text" placeholder="Код страны для всех" v-model="newCountryCodeModel"
                   @keyup.enter="props.changeAllWithLoader({country_code: newCountryCodeModel});  newCountryCodeModel = null;"/>
          </label>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <div class="admin-table-element">
          <template v-if="props.column.field === 'service'">
            <img :src="props.row['service_img_path']" alt="" width="20px">
            <div :class="getClass(props.formattedRow)">
              {{ props.formattedRow[props.column.field] }}
            </div>
          </template>
          <template v-else-if="props.column.field === 'country'">
            <img :src="props.row['country_img_path']" alt="" width="20px">
            <div :class="getClass(props.formattedRow)">
              {{ props.formattedRow[props.column.field] }}
            </div>
          </template>
          <template v-else-if="props.column.field === 'wholesale_price'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {wholesale_price: $event.target.value})"
                   type="text"
                   :class="getClass(props.formattedRow)"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'service_code'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {service_code: $event.target.value})"
                   type="text"
                   :class="getClass(props.formattedRow)"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'country_code'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {country_code: $event.target.value})"
                   type="text"
                   :class="getClass(props.formattedRow)"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'ratio'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {ratio: $event.target.value})"
                   type="text"
                   :class="getClass(props.formattedRow)"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'supplier_price'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {supplier_price: $event.target.value})"
                   type="text"
                   :class="getClass(props.formattedRow)"
                   :value="props.formattedRow[props.column.field].split('|')[0]"
                   :placeholder="props.formattedRow[props.column.field].split('|')[0]"
            >
            {{ props.formattedRow[props.column.field].split('|')[1] }}
          </template>
          <template v-else-if="props.column.field === 'is_active'">
            <button v-if="props.formattedRow[props.column.field]" class="btn btn-success w-100"
                    @click="props.changeItemWithLoader(props.row['id'], {is_active: false})">ON
            </button>
            <button v-else class="btn btn-danger w-100"
                    @click="props.changeItemWithLoader(props.row['id'], {is_active: true})">OFF
            </button>
          </template>

          <template v-else-if="props.column.field === 'allow_multiple_sms'">
            <button v-if="props.formattedRow[props.column.field]" class="btn btn-success w-100"
                    @click="props.changeItemWithLoader(props.row['id'], {allow_multiple_sms: false})">ON
            </button>
            <button v-else class="btn btn-danger w-100"
                    @click="props.changeItemWithLoader(props.row['id'], {allow_multiple_sms: true})">OFF
            </button>
          </template>
          <template v-else>
            <span :class="getClass(props.formattedRow)">{{ props.formattedRow[props.column.field] }}</span>
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from "../../../components/baseTable";

export default {
  name: "providerServiceVariationsTable",
  components: {
    BaseTable
  },
  data: function () {
    return {
      addModalInputData: {
        addModalTitle: 'Добавить оптовые цены',
        addModalUrl: '/providerServiceVariations/addMany',
        addModalMethod: 'put',
        items: {
          services: {
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Выберите сервис',
            viewLabel: 'titleWithId',
          },
          countries: {
            multiple: true,
            getDataLink: '/countries/getList',
            placeholder: 'Выберите страну',
            viewLabel: 'titleWithId',
          },
          providers: {
            multiple: true,
            getDataLink: '/providers/getList',
            placeholder: 'Выберите провайдера',
            viewLabel: 'titleWithId',
          },
          // wholesalePrice: {
          //   simpleInputType: 'text',
          //   placeholder: 'Оптовая цена',
          //   selectedKey: 'wholesalePrice',
          // },
          ratio: {
            simpleInputType: 'text',
            placeholder: 'Приоритет',
            selectedKey: 'ratio',
          },
          supplier_price: {
            simpleInputType: 'text',
            placeholder: 'Цена поставщика',
            selectedKey: 'supplierPrice',
          },
        }
      },
      wrapperFiltersData: {
        title: "Оптовые цены",
        filtersUrl: "/providerServiceVariations/getList",
        items: {
          countries: {
            enable: true,
            multiple: true,
            getDataLink: '/countries/getList',
            placeholder: 'Страны',
            viewLabel: 'titleWithId',
            column: 1,
          },
          services: {
            enable: true,
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Сервисы',
            viewLabel: 'titleWithId',
            column: 1
          },

          priceFrom: {
            enable: true,
            simpleInputType: 'text',
            placeholder: 'Цена (от)',
            selectedKey: 'priceFrom',
            column: 2
          },
          priceTo: {
            enable: true,
            simpleInputType: 'text',
            placeholder: 'Цена (до)',
            selectedKey: 'priceTo',
            column: 2
          },
          providers: {
            enable: true,
            multiple: true,
            getDataLink: '/providers/getList',
            placeholder: 'Провайдеры',
            viewLabel: 'titleWithId',
            column: 3
          },
          emptyService: {
            enable: true,
            selectedKey: 'onlyEmptyServiceCode',
            upperSpanText: 'Только пустой сервис код: ',
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            placeholder: 'active',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            column: 4
          },
          activityStatus: {
            enable: true,
            selectedKey: 'activityStatus',
            upperSpanText: 'Только активные позиции: ',
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            placeholder: 'active',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            column: 2
          },
          providerPrice: {
            enable: true,
            placeholder: 'Цена поставщика',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'providerPrice',
            data: [
              {
                id: 1,
                title: 'Без фильтров цены поставщика'
              },
              {
                id: 2,
                title: 'Только пустая цена поставщика'
              },
              {
                id: 3,
                title: 'Только не пустая цена поставщика'
              },
            ],
            column: 3,
            defaultValue: {
              id: 0,
              title: 'Без фильтров цены поставщика'
            },
            setDefaultInFilters: 1,
          },
        }
      },
      columns: [
        {
          label: 'Провайдер',
          field: 'provider',
          width: '12%',
          html: true
        },
        {
          label: 'Страна',
          field: 'country',
          width: '12%',
          html: true
        },
        {
          label: 'Сервис',
          field: 'service',
          width: '12%',
          html: true
        },
        {
          label: 'Оптовая цена',
          field: 'wholesale_price',
          sortFn: 'sortNumber',
          html: true,
          width: '9%',
        },
        {
          label: 'Розничная цена',
          field: 'price',
          sortFn: 'sortNumber',
          html: true,
          width: '7%',
        },
        {
          label: 'Код сервиса',
          field: 'service_code',
          html: true,
          sortable: false,
          width: '8%',
        },
        {
          label: 'Код страны',
          field: 'country_code',
          html: true,
          sortable: false,
          width: '9%',
        },
        {
          label: 'Приоритет',
          field: 'ratio',
          sortFn: 'sortNumber',
          html: true,
          width: '8%',
        },
        {
          label: 'Активность⠀⠀',
          field: 'is_active',
          width: '8%',
        },
        {
          label: 'Несколько смс⠀⠀',
          field: 'allow_multiple_sms',
          width: '8%',
        },
        {
          label: 'Цена поставщика⠀⠀',
          field: 'supplier_price',
          type: 'number',
          width: '10%',
        },
      ],
      tableTitle: 'Service variations',
      changeAllWithLoaderUrl: '/providerServiceVariations/editMany',
      changeAllWithLoaderMethod: 'post',
      changeItemWithLoaderUrl: '/providerServiceVariations',
      changeItemWithLoaderMethod: 'post',
      newPriceModel: null,
      newProviderPriceModel: null,
      newRatioModel: null,
      newCodeModel: null,
      newCountryCodeModel: null,
    };
  },

  methods: {
    listToShow(list) {
      let data = [];
      for (let elem of list) {
        let supplierPrice = elem['supplier_price'] ?? '';
        data.push({
          id: elem['id'],
          updated_at: elem['updated_at'],
          wholesale_price: Number(elem['wholesale_price']).toFixed(2),
          provider: elem['provider']['title'] + ' (' + elem['provider']['id'] + ')',
          country: elem['service_variation']['country']['title'] + ' (' + elem['service_variation']['country']['id'] + ')',
          country_img_path: elem['service_variation']['country']['img_path'],
          service: elem['service_variation']['service']['title'] + ' (' + elem['service_variation']['service']['id'] + ')',
          service_img_path: elem['service_variation']['service']['img_path'],
          country_code: elem['country_code'],
          service_code: elem['service_code'],
          ratio: elem['ratio'],
          is_active: elem['is_active'],
          allow_multiple_sms: elem['allow_multiple_sms'],
          price: elem['service_variation']['price'],
          supplier_price: supplierPrice + '|' + elem['provider']['rate']['currency'],
        });
      }
      return data;
    },
    getClass(row) {
      let balance = parseInt(row['price'].replace(/\s/g, ""));
      if (isNaN(balance)){
        return '';
      }
      if (balance < row['wholesale_price']){
        return 'background-color: red';
      }
      // if (balance < (row['wholesale_price'] * 3)) {
      //   return 'colorOrange';
      // }
      return '';
    },
  },
}
</script>
<style scoped>

</style>
