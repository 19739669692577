<template>
  <PieChartGenerator
      :chart-options="options"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
  />
</template>

<script>
import {Pie as PieChartGenerator} from 'vue-chartjs/legacy'
import {Chart as ChartJS, Title, Tooltip, Legend, ArcElement, LinearScale, CategoryScale} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LinearScale, ArcElement, CategoryScale)


export default {
  name: "pieChart",
  components: {
    PieChartGenerator,
  },
  created() {
    this.prepareTitle();
  },
  beforeUpdate() {
    this.prepareTitle();
  },
  data() {
    return {
      options: this.chartOptions
    }
  },
  props: {
    title: {type: String},
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {
          responsive: true,
        }
      }
    },
    chartId: {
      type: String,
      default: 'pie-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 700
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    plugins: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    prepareTitle() {
      if (this.title) {
        this.options = {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: this.title
            }
          }
        }
      }
    }
  }
}
</script>
